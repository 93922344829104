// export const RAGNAROK_ADDRESS = `0xfE75A947acd885d52132e29555a3c20144443201`
// export const VIB_ADDRESS = `0x53E2244f567dCB85d5c6181985FdA7575198ED06`
// export const ADA_ADDRESS = `0xE86119954B3DF3F94C8Fc28d35Dca9330Fab796e`
// export const ORACLE_VIB_ADDRESS = `0xB71c012d584e4387FEC7D2E526dF4F0c286D015D`
// export const NICK_FURY_ADDRESS = ``

// production
export const RAGNAROK_ADDRESS = `0x761B25bC068a047A4A53eB9A12D89519da42aaE0`
export const VIB_ADDRESS = `0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5`
export const ADA_ADDRESS = `0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB`
export const ORACLE_VIB_ADDRESS = `0x442c19cE325025DceDe70bF894cf2C8aC3726fAC`
export const NICK_FURY_ADDRESS = `0x8fb60dd3557c491e04d00a06fdc0618423a3c618`

export const USDC_ADDRESS = `0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56`

export const TOKEN_ADDRESSES = [
  {
    chainId: 56,
    address: VIB_ADDRESS,
    name: 'Never',
    symbol: 'NEVER',
    decimals: 18,
    logoURI: `https://raw.githubusercontent.com/neverswap/assets/master/blockchains/smartchain/assets/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/logo.png`
  },
  {
    chainId: 56,
    address: USDC_ADDRESS,
    name: 'BUSD',
    symbol: 'BUSD',
    decimals: 18,
    logoURI: `https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766`
  },
  {
    chainId: 56,
    address: ADA_ADDRESS,
    name: 'Ever',
    symbol: 'EVE',
    decimals: 18,
    logoURI: `https://raw.githubusercontent.com/neverswap/assets/master/blockchains/smartchain/assets/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/logo.png`
  }
] as TTokenData[]

export interface TTokenData {
  chainId: number
  address: string
  name: string
  symbol: string
  decimals: number
  logoURI: string
}
