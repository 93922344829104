// import { useContract } from 'components/SearchModal/MintFunction'
// import { BigNumber } from 'ethers'
// import useTokenPriceByLp from 'hooks/useTokenPriceByLp'
// import { useCallback, useEffect, useState } from 'react'
// import { weiToFixed } from 'utils/math-helpers'
import NEVEREVER_ABI from 'constants/abis/staking/neverever.json'
import LEGENDARY_ABI from 'constants/abis/staking/legendary.json'
import DIVIDEND_ABI from 'constants/abis/staking/dividend.json'
import LP_ABI from 'constants/abis/staking/lp.json'
import { getWeb3NoAccount } from 'utils/web3'
import { AbiItem } from 'web3-utils'
import ERC20_ABI from 'constants/abis/erc20.json'
import { useCallback, useEffect, useState } from 'react'
import mkrMulticall from 'utils/mkrMulticall'
import { DIVIDEND_STAKING_LIST, NEVERDIE_FARM, NEVERDIE_STAKING_LIST, NEVER_FARM, STAKING_LIST } from './constants'
import {
  IDataResult,
  IGetReservesResult,
  IPoolInfoResult,
  IStakingSupplyResult,
  ITotalAllocPointResult,
  ITotalSupplyResult,
  reserveType,
  INeverDieDataResult,
  IDataDividendResult,
  IRewardPerBlockResult
} from './types'
import useTokenPriceByLp from 'hooks/useTokenPriceByLp'
import { weiToFixed } from 'utils/math-helpers'
import { BigNumber } from 'ethers'

const BLOCK_PER_YEAR = 10512000

export const usePoolData = (): { poolData: IDataResult[] } => {
  const { price: neverPrice } = useTokenPriceByLp()
  const fairlaunchAddress = NEVER_FARM[56]
  const [poolData, setPoolData] = useState<IDataResult[]>([])
  const [emissionRate, setEmissionRate] = useState<BigNumber | null>(null)
  const web3 = getWeb3NoAccount()
  const fairlaunchContract = new web3.eth.Contract((NEVEREVER_ABI as unknown) as AbiItem, fairlaunchAddress)

  useEffect(() => {
    if (fairlaunchContract)
      fairlaunchContract?.methods
        .neverPerBlock()
        .call()
        .then((result: any) => {
          setEmissionRate(result)
        })
  }, [])

  const stakingSupplyCalls = STAKING_LIST.map(sl => {
    return {
      address: sl.token1 === null ? sl.address : sl.lpAddress,
      name: 'balanceOf',
      params: [fairlaunchAddress]
    }
  })

  const totalSupplyCalls = STAKING_LIST.map(sl => {
    return {
      address: sl.lpAddress,
      name: 'totalSupply',
      params: []
    }
  })

  const getReservesCalls = STAKING_LIST.map(sl => {
    return {
      address: sl.lpAddress,
      name: 'getReserves',
      params: []
    }
  })

  const totalAllocPointCalls = STAKING_LIST.map(sl => {
    return {
      address: fairlaunchAddress,
      name: 'totalAllocPoint',
      params: []
    }
  })

  const poolInfoCalls = STAKING_LIST.map(sl => {
    return {
      address: fairlaunchAddress,
      name: 'poolInfo',
      params: [sl.poolId]
    }
  })

  const getAllData = useCallback(async () => {
    const stakingSupplyResult = (await mkrMulticall(56, ERC20_ABI, stakingSupplyCalls)) as any
    const stakingSupplyResultMapping = STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        stakingSupply: stakingSupplyResult[i][0]
      } as IStakingSupplyResult
    })

    const totalSupplyResult = (await mkrMulticall(56, LP_ABI, totalSupplyCalls)) as any
    const totalSupplyResultMapping = STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        totalSupply: totalSupplyResult[i][0]
      } as ITotalSupplyResult
    })

    const getReverseResult = (await mkrMulticall(56, LP_ABI, getReservesCalls)) as any
    const getReverseResultMapping = STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        reserves: getReverseResult[i]
      } as IGetReservesResult
    })

    const totalAllocPointCallsResult = (await mkrMulticall(56, NEVEREVER_ABI, totalAllocPointCalls)) as any
    const totalAllocPointCallsResultMapping = STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        totalAllocPoint: totalAllocPointCallsResult[i][0]
      } as ITotalAllocPointResult
    })

    const poolInfoCallsResult = (await mkrMulticall(56, NEVEREVER_ABI, poolInfoCalls)) as any
    const poolInfoCallsResultMapping = STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        poolInfo: poolInfoCallsResult[i]
      } as IPoolInfoResult
    })

    // edit this if someting change on constants
    const lpPriceMultiplier = [
      1,
      neverPrice!,
      neverPrice!,
      neverPrice!,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1
    ]

    const poolDataResult: IDataResult[] = STAKING_LIST.map((sl, i) => {
      const totalAlloc = totalAllocPointCallsResultMapping[i].totalAllocPoint.toNumber()
      const rewardPerBlock = parseFloat(weiToFixed(emissionRate?.toString(), 18))
      const alloc = poolInfoCallsResultMapping[i].poolInfo.allocPoint.toNumber()
      const totalSupply = parseFloat(weiToFixed(totalSupplyResultMapping[i].totalSupply.toString(), 18))
      const stakingSupply = parseFloat(weiToFixed(stakingSupplyResultMapping[i].stakingSupply.toString(), 18))
      // let lpPrice = 1
      // if (sl.reserveType === reserveType.reserve2) {
      //   lpPrice = neverPrice!
      //   console.log(1)
      // } else {

      //   console.log(2)
      // }

      let lpPrice = 1

      // Duel token pool
      if (sl.token1 !== null) {
        const reserves = weiToFixed(
          sl.reserveType === reserveType.reserve0
            ? getReverseResultMapping[i].reserves?._reserve0.toString()
            : getReverseResultMapping[i].reserves?._reserve1.toString(),
          18
        )
        lpPrice = (parseFloat(reserves) * lpPriceMultiplier[i]) / (totalSupply / 2)
      } else {
        // Single token pool
        lpPrice = neverPrice!
      }

      const totalValueLocked = lpPrice * stakingSupply
      const resultAPR = ((alloc / totalAlloc) * BLOCK_PER_YEAR * rewardPerBlock * neverPrice! * 100) / totalValueLocked
      const emissionRatePerDay = parseFloat(weiToFixed(emissionRate?.toString(), 18)) * 28800
      const rewardPerDay = (alloc * emissionRatePerDay) / totalAlloc
      return {
        apr: resultAPR,
        lpPrice: lpPrice,
        rewardPerDay: rewardPerDay,
        stakingData: sl,
        tvl: totalValueLocked
      }
    })
    setPoolData(poolDataResult)
    // End calculate all pool
  }, [neverPrice, emissionRate])

  useEffect(() => {
    if (neverPrice && emissionRate) getAllData()
  }, [neverPrice, emissionRate])

  return { poolData }
}

export const useNeverDiePoolData = (): { poolData: INeverDieDataResult[] } => {
  const { price: neverPrice } = useTokenPriceByLp()
  const fairlaunchAddress = NEVERDIE_FARM[56]
  const [poolData, setPoolData] = useState<INeverDieDataResult[]>([])
  const [emissionRate, setEmissionRate] = useState<BigNumber | null>(null)
  const web3 = getWeb3NoAccount()
  const fairlaunchContract = new web3.eth.Contract((LEGENDARY_ABI as unknown) as AbiItem, fairlaunchAddress)

  useEffect(() => {
    if (fairlaunchContract)
      fairlaunchContract?.methods
        .neverdiePerBlock()
        .call()
        .then((result: any) => {
          setEmissionRate(result)
        })
  }, [])

  const stakingSupplyCalls = NEVERDIE_STAKING_LIST.map(sl => {
    return {
      address: sl.token1 === null ? sl.address : sl.lpAddress,
      name: 'balanceOf',
      params: [fairlaunchAddress]
    }
  })

  const totalSupplyCalls = NEVERDIE_STAKING_LIST.map(sl => {
    return {
      address: sl.lpAddress,
      name: 'totalSupply',
      params: []
    }
  })

  const getReservesCalls = NEVERDIE_STAKING_LIST.map(sl => {
    return {
      address: sl.lpAddress,
      name: 'getReserves',
      params: []
    }
  })

  const totalAllocPointCalls = NEVERDIE_STAKING_LIST.map(sl => {
    return {
      address: fairlaunchAddress,
      name: 'totalAllocPoint',
      params: []
    }
  })

  const poolInfoCalls = NEVERDIE_STAKING_LIST.map(sl => {
    return {
      address: fairlaunchAddress,
      name: 'poolInfo',
      params: [sl.poolId]
    }
  })

  const getAllData = useCallback(async () => {
    const stakingSupplyResult = (await mkrMulticall(56, ERC20_ABI, stakingSupplyCalls)) as any
    const stakingSupplyResultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        stakingSupply: stakingSupplyResult[i][0]
      } as IStakingSupplyResult
    })

    const totalSupplyResult = (await mkrMulticall(56, LP_ABI, totalSupplyCalls)) as any
    const totalSupplyResultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        totalSupply: totalSupplyResult[i][0]
      } as ITotalSupplyResult
    })

    const getReverseResult = (await mkrMulticall(56, LP_ABI, getReservesCalls)) as any
    const getReverseResultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        reserves: getReverseResult[i]
      } as IGetReservesResult
    })

    const totalAllocPointCallsResult = (await mkrMulticall(56, LEGENDARY_ABI, totalAllocPointCalls)) as any
    const totalAllocPointCallsResultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        totalAllocPoint: totalAllocPointCallsResult[i][0]
      } as ITotalAllocPointResult
    })

    const poolInfoCallsResult = (await mkrMulticall(56, LEGENDARY_ABI, poolInfoCalls)) as any
    const poolInfoCallsResultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        poolInfo: poolInfoCallsResult[i]
      } as IPoolInfoResult
    })

    // edit this if someting change on constants
    const lpPriceMultiplier = [1, neverPrice!, 1]

    const poolDataResult: INeverDieDataResult[] = NEVERDIE_STAKING_LIST.map((sl, i) => {
      const totalAlloc = totalAllocPointCallsResultMapping[i].totalAllocPoint.toNumber()
      const alloc = poolInfoCallsResultMapping[i].poolInfo.allocPoint.toNumber()
      const totalSupply = parseFloat(weiToFixed(totalSupplyResultMapping[i].totalSupply.toString(), 18))
      const stakingSupply = parseFloat(weiToFixed(stakingSupplyResultMapping[i].stakingSupply.toString(), 18))
      const reserves = weiToFixed(
        sl.reserveType === reserveType.reserve0
          ? getReverseResultMapping[i].reserves?._reserve0.toString()
          : getReverseResultMapping[i].reserves?._reserve1.toString(),
        18
      )
      const lpPrice = (parseFloat(reserves) * lpPriceMultiplier[i]) / (totalSupply / 2)

      const totalValueLocked = lpPrice * stakingSupply
      const emissionRatePerDay = parseFloat(weiToFixed(emissionRate?.toString(), 18)) * 28800
      const rewardPerDay = (alloc * emissionRatePerDay) / totalAlloc
      return {
        // apr: resultAPR,
        lpPrice: lpPrice,
        rewardPerDay: rewardPerDay,
        stakingData: sl,
        tvl: totalValueLocked
      }
    })
    setPoolData(poolDataResult)
    // End calculate all pool
  }, [neverPrice, emissionRate])

  useEffect(() => {
    if (neverPrice && emissionRate) getAllData()
  }, [neverPrice, emissionRate])

  return { poolData }
}

export const useDividendPoolData = (): { poolData: IDataDividendResult[] } => {
  // const { price: neverPrice } = useTokenPriceByLp()
  const [poolData, setPoolData] = useState<IDataDividendResult[]>([])

  const rewardPerBlockCalls = DIVIDEND_STAKING_LIST.map(sl => {
    return {
      address: sl.dividendAddress,
      name: 'rewardPerBlock',
      params: []
    }
  })

  const stakingSupplyCalls = DIVIDEND_STAKING_LIST.map(sl => {
    return {
      address: sl.address,
      name: 'balanceOf',
      params: [sl.dividendAddress]
    }
  })

  // const totalSupplyCalls = DIVIDEND_STAKING_LIST.map(sl => {
  //   return {
  //     address: sl.address,
  //     name: 'totalSupply',
  //     params: []
  //   }
  // })

  const getAllData = useCallback(async () => {
    const rewardPerBlockResult = (await mkrMulticall(56, DIVIDEND_ABI, rewardPerBlockCalls)) as any
    const rewardPerBlockResultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        rewardPerBlock: rewardPerBlockResult[i][0]
      } as IRewardPerBlockResult
    })

    const stakingSupplyResult = (await mkrMulticall(56, ERC20_ABI, stakingSupplyCalls)) as any
    const stakingSupplyResultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
      return {
        stakingData: sl,
        stakingSupply: stakingSupplyResult[i][0]
      } as IStakingSupplyResult
    })

    // const totalSupplyResult = (await mkrMulticall(56, LP_ABI, totalSupplyCalls)) as any
    // const totalSupplyResultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
    //   return {
    //     stakingData: sl,
    //     totalSupply: totalSupplyResult[i][0]
    //   } as ITotalSupplyResult
    // })

    // edit this if someting change on constants
    const poolDataResult: IDataDividendResult[] = DIVIDEND_STAKING_LIST.map((sl, i) => {
      // const totalSupply = parseFloat(weiToFixed(totalSupplyResultMapping[i].totalSupply.toString(), 18))
      const stakingSupply = parseFloat(weiToFixed(stakingSupplyResultMapping[i].stakingSupply.toString(), 18))

      const totalValueLocked = stakingSupply
      // const emissionRatePerDay = parseFloat(weiToFixed(emissionRate?.toString(), 18)) * 28800
      const rewardPerDay = parseFloat(
        weiToFixed(rewardPerBlockResultMapping[i].rewardPerBlock.mul(28800).toString(), 2)
      )
      return {
        // apr: resultAPR,
        rewardPerDay: rewardPerDay,
        stakingData: sl,
        tvl: totalValueLocked
      }
    })

    setPoolData(poolDataResult)
    // End calculate all pool
  }, [])

  useEffect(() => {
    getAllData()
  }, [])

  return { poolData }
}

//
//
//
//
export const useGetEveBusdData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[0],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetNeverBNBData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[1],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetNeverEVEData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[2],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetNeverBUSDData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[3],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetUsdtBnbData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[4],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetUsdcBNBData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[5],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetEveUsdtData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[6],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
export const useGetEveUsdcData = (): { poolData: IDataResult } => {
  const [poolData] = useState<IDataResult>({
    stakingData: STAKING_LIST[7],
    apr: 0,
    tvl: 0,
    lpPrice: 0,
    rewardPerDay: 0
  })
  return {
    poolData
  }
}
