import { BigNumber } from '@ethersproject/bignumber'
import { useContract } from 'components/SearchModal/MintFunction'
import ERC20_ABI from 'constants/abis/erc20.json'
import VIBRANIUM_TOKEN_ABI from 'constants/abis/vibranium.json'
import NEVEREVER_ABI from 'constants/abis/staking/neverever.json'
import LEGENDARY_ABI from 'constants/abis/staking/legendary.json'
import DIVIDEND_ABI from 'constants/abis/staking/dividend.json'
import { useActiveWeb3React } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { weiToFixed } from 'utils/math-helpers'
import mkrMulticall from 'utils/mkrMulticall'
import {
  DIVIDEND_STAKING_LIST,
  NEVERDIE_FARM,
  NEVERDIE_STAKING_LIST,
  NEVER_FARM,
  STAKING_LIST,
  VIBRANIUM_TOKEN_ADDRESSES
} from './constants'
import {
  TStakingAllowance,
  TStakingBalance,
  TStakingDepositBalance,
  TStakingDividendAllowance,
  TStakingDividendBalance,
  TStakingDividendDepositBalance,
  TStakingDividendReward,
  TStakingReward
} from './types'

// Never farm

const useNeverFarmStakingAllowance = (): {
  allowanceResult: Array<TStakingAllowance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [allowanceResult, setAllowanceResult] = useState<Array<TStakingAllowance>>([])

  const update = useCallback(() => {
    if (account && chainId && allowanceResult.length === 0) {
      const fairlaunchAddress = NEVER_FARM[chainId]
      const calls = STAKING_LIST.map(sl => {
        return {
          address: sl.token1 !== null ? sl.lpAddress : sl.address,
          name: 'allowance',
          params: [account, fairlaunchAddress]
        }
      })
      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const resultMapping = STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            allowance: result[i][0]
          } as TStakingAllowance
        })
        setAllowanceResult(resultMapping)
      })
    }
  }, [account, chainId, allowanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { allowanceResult, update }
}

const useNeverFarmStakingBalance = (): {
  balanceResult: Array<TStakingBalance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [balanceResult, setBalanceResult] = useState<Array<TStakingBalance>>([])

  const update = useCallback(() => {
    if (account && chainId && balanceResult.length === 0) {
      const calls = STAKING_LIST.map(sl => {
        return {
          address: sl.token1 !== null ? sl.lpAddress : sl.address,
          name: 'balanceOf',
          params: [account]
        }
      })
      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const resultMapping = STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            balance: result[i][0]
          } as TStakingBalance
        })
        setBalanceResult(resultMapping)
      })
    }
  }, [account, chainId, balanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { balanceResult, update }
}

const useNeverFarmStakingDepositBalance = (): {
  depositBalanceResult: Array<TStakingDepositBalance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [depositBalanceResult, setDepositBalanceResult] = useState<Array<TStakingDepositBalance>>([])

  const update = useCallback(() => {
    if (account && chainId && depositBalanceResult.length === 0) {
      const fairlaunchAddress = NEVER_FARM[chainId]
      const calls = STAKING_LIST.map(sl => {
        return {
          address: fairlaunchAddress,
          name: 'userInfo',
          params: [sl.poolId, account]
        }
      })
      mkrMulticall(chainId, NEVEREVER_ABI, calls).then(result => {
        const resultMapping = STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            balance: result[i].amount
          } as TStakingDepositBalance
        })
        setDepositBalanceResult(resultMapping)
      })
    }
  }, [account, chainId, depositBalanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { depositBalanceResult, update }
}

const useNeverFarmStakingReward = (): {
  stakingRewardResult: Array<TStakingReward>
  totalUnclaimReward: number
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [stakingRewardResult, setStakingRewardResult] = useState<Array<TStakingReward>>([])
  const [totalUnclaimReward, setTotalUnclaimReward] = useState(0)

  const update = useCallback(() => {
    if (account && chainId && stakingRewardResult.length === 0) {
      const fairluanchAddress = NEVER_FARM[chainId]
      const calls = STAKING_LIST.map(sl => {
        return {
          address: fairluanchAddress,
          name: 'pendingNever',
          params: [sl.poolId, account]
        }
      })

      mkrMulticall(chainId, NEVEREVER_ABI, calls).then(result => {
        let sumOfUnClaimReward = BigNumber.from(0)
        const resultMapping = STAKING_LIST.map((sl, i) => {
          sumOfUnClaimReward = sumOfUnClaimReward.add(result[i][0])
          return {
            stakingData: sl,
            reward: result[i][0]
          } as TStakingReward
        })
        setTotalUnclaimReward(parseFloat(weiToFixed(sumOfUnClaimReward.toString(), 2)))
        setStakingRewardResult(resultMapping)
      })
    }
  }, [account, chainId, stakingRewardResult])

  useEffect(() => {
    update()
  }, [update])

  return { stakingRewardResult, totalUnclaimReward, update }
}

const useNeverFarmVibraniumBalanceOf = (): {
  balance: BigNumber
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [balance, setBalance] = useState(BigNumber.from(0))

  const tokenContract = useContract(VIBRANIUM_TOKEN_ADDRESSES[chainId ? chainId : 56], VIBRANIUM_TOKEN_ABI)

  const update = useCallback(async () => {
    if (account && tokenContract) {
      const result = await tokenContract.balanceOf(account)
      setBalance(result)
    }
  }, [account, balance])

  useEffect(() => {
    update()
  }, [update])

  return { balance, update }
}

const useGetVibraniumLockedAmount = (): {
  lockedAmount: BigNumber
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [lockedAmount, setLockedAmount] = useState(BigNumber.from(0))

  const tokenContract = useContract(VIBRANIUM_TOKEN_ADDRESSES[chainId ? chainId : 56], VIBRANIUM_TOKEN_ABI)

  const update = useCallback(async () => {
    if (account && tokenContract) {
      const result = await tokenContract.lockOf(account)
      setLockedAmount(result)
    }
  }, [account, lockedAmount])

  useEffect(() => {
    update()
  }, [update])

  return { lockedAmount, update }
}

// Neverdie farm

const useNeverdieFarmStakingAllowance = (): {
  allowanceResult: Array<TStakingAllowance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [allowanceResult, setAllowanceResult] = useState<Array<TStakingAllowance>>([])

  const update = useCallback(() => {
    if (account && chainId && allowanceResult.length === 0) {
      const fairlaunchAddress = NEVERDIE_FARM[chainId]
      const calls = NEVERDIE_STAKING_LIST.map(sl => {
        return {
          address: sl.token1 !== null ? sl.lpAddress : sl.address,
          name: 'allowance',
          params: [account, fairlaunchAddress]
        }
      })
      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const resultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            allowance: result[i][0]
          } as TStakingAllowance
        })
        setAllowanceResult(resultMapping)
      })
    }
  }, [account, chainId, allowanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { allowanceResult, update }
}

const useNeverdieFarmStakingBalance = (): {
  balanceResult: Array<TStakingBalance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [balanceResult, setBalanceResult] = useState<Array<TStakingBalance>>([])

  const update = useCallback(() => {
    if (account && chainId && balanceResult.length === 0) {
      const calls = NEVERDIE_STAKING_LIST.map(sl => {
        return {
          address: sl.token1 !== null ? sl.lpAddress : sl.address,
          name: 'balanceOf',
          params: [account]
        }
      })
      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const resultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            balance: result[i][0]
          } as TStakingBalance
        })
        setBalanceResult(resultMapping)
      })
    }
  }, [account, chainId, balanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { balanceResult, update }
}

const useNeverdieFarmStakingDepositBalance = (): {
  depositBalanceResult: Array<TStakingDepositBalance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [depositBalanceResult, setDepositBalanceResult] = useState<Array<TStakingDepositBalance>>([])

  const update = useCallback(() => {
    if (account && chainId && depositBalanceResult.length === 0) {
      const fairlaunchAddress = NEVERDIE_FARM[chainId]
      const calls = NEVERDIE_STAKING_LIST.map(sl => {
        return {
          address: fairlaunchAddress,
          name: 'userInfo',
          params: [sl.poolId, account]
        }
      })
      mkrMulticall(chainId, LEGENDARY_ABI, calls).then(result => {
        const resultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            balance: result[i].amount
          } as TStakingDepositBalance
        })
        setDepositBalanceResult(resultMapping)
      })
    }
  }, [account, chainId, depositBalanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { depositBalanceResult, update }
}

const useNeverdieFarmStakingReward = (): {
  stakingRewardResult: Array<TStakingReward>
  totalUnclaimReward: number
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [stakingRewardResult, setStakingRewardResult] = useState<Array<TStakingReward>>([])
  const [totalUnclaimReward, setTotalUnclaimReward] = useState(0)

  const update = useCallback(() => {
    if (account && chainId && stakingRewardResult.length === 0) {
      const fairluanchAddress = NEVERDIE_FARM[chainId]
      const calls = NEVERDIE_STAKING_LIST.map(sl => {
        return {
          address: fairluanchAddress,
          name: 'pendingNeverDie',
          params: [sl.poolId, account]
        }
      })

      mkrMulticall(chainId, LEGENDARY_ABI, calls).then(result => {
        let sumOfUnClaimReward = BigNumber.from(0)
        const resultMapping = NEVERDIE_STAKING_LIST.map((sl, i) => {
          sumOfUnClaimReward = sumOfUnClaimReward.add(result[i][0])
          return {
            stakingData: sl,
            reward: result[i][0]
          } as TStakingReward
        })
        setTotalUnclaimReward(parseFloat(weiToFixed(sumOfUnClaimReward.toString(), 2)))
        setStakingRewardResult(resultMapping)
      })
    }
  }, [account, chainId, stakingRewardResult])

  useEffect(() => {
    update()
  }, [update])

  return { stakingRewardResult, totalUnclaimReward, update }
}

// Dividen hook
const useDividendStakingAllowance = (): {
  allowanceResult: Array<TStakingDividendAllowance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [allowanceResult, setAllowanceResult] = useState<Array<TStakingDividendAllowance>>([])

  const update = useCallback(() => {
    if (account && chainId && allowanceResult.length === 0) {
      const calls = DIVIDEND_STAKING_LIST.map(sl => {
        return {
          address: sl.address,
          name: 'allowance',
          params: [account, sl.dividendAddress]
        }
      })
      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const resultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            allowance: result[i][0]
          } as TStakingDividendAllowance
        })
        setAllowanceResult(resultMapping)
      })
    }
  }, [account, chainId, allowanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { allowanceResult, update }
}

const useDividendStakingBalance = (): {
  balanceResult: Array<TStakingDividendBalance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [balanceResult, setBalanceResult] = useState<Array<TStakingDividendBalance>>([])

  const update = useCallback(() => {
    if (account && chainId && balanceResult.length === 0) {
      const calls = DIVIDEND_STAKING_LIST.map(sl => {
        return {
          address: sl.address,
          name: 'balanceOf',
          params: [account]
        }
      })
      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const resultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            balance: result[i][0]
          } as TStakingDividendBalance
        })
        setBalanceResult(resultMapping)
      })
    }
  }, [account, chainId, balanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { balanceResult, update }
}

const useDividendStakingDepositBalance = (): {
  depositBalanceResult: Array<TStakingDividendDepositBalance>
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [depositBalanceResult, setDepositBalanceResult] = useState<Array<TStakingDividendDepositBalance>>([])

  const update = useCallback(() => {
    if (account && chainId && depositBalanceResult.length === 0) {
      const calls = DIVIDEND_STAKING_LIST.map(sl => {
        return {
          address: sl.dividendAddress,
          name: 'userInfo',
          params: [account]
        }
      })
      mkrMulticall(chainId, DIVIDEND_ABI, calls).then(result => {
        const resultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
          return {
            stakingData: sl,
            balance: result[i].amount
          } as TStakingDividendDepositBalance
        })
        setDepositBalanceResult(resultMapping)
      })
    }
  }, [account, chainId, depositBalanceResult])

  useEffect(() => {
    update()
  }, [update])

  return { depositBalanceResult, update }
}

const useDividendStakingReward = (): {
  stakingRewardResult: Array<TStakingDividendReward>
  totalUnclaimReward: number
  update: () => void
} => {
  const { account, chainId } = useActiveWeb3React()
  const [stakingRewardResult, setStakingRewardResult] = useState<Array<TStakingDividendReward>>([])
  const [totalUnclaimReward, setTotalUnclaimReward] = useState(0)

  const update = useCallback(() => {
    if (account && chainId && stakingRewardResult.length === 0) {
      const calls = DIVIDEND_STAKING_LIST.map(sl => {
        return {
          address: sl.dividendAddress,
          name: 'pendingReward',
          params: [account]
        }
      })

      mkrMulticall(chainId, DIVIDEND_ABI, calls).then(result => {
        let sumOfUnClaimReward = BigNumber.from(0)
        const resultMapping = DIVIDEND_STAKING_LIST.map((sl, i) => {
          sumOfUnClaimReward = sumOfUnClaimReward.add(result[i][0])
          return {
            stakingData: sl,
            reward: result[i][0]
          } as TStakingDividendReward
        })
        setTotalUnclaimReward(parseFloat(weiToFixed(sumOfUnClaimReward.toString(), 2)))
        setStakingRewardResult(resultMapping)
      })
    }
  }, [account, chainId, stakingRewardResult])

  useEffect(() => {
    update()
  }, [update])

  return { stakingRewardResult, totalUnclaimReward, update }
}

export {
  // Never farm
  useNeverFarmStakingAllowance,
  useNeverFarmStakingBalance,
  useNeverFarmStakingDepositBalance,
  useNeverFarmStakingReward,
  useNeverFarmVibraniumBalanceOf,
  useGetVibraniumLockedAmount,
  // Neverdie Farm
  useNeverdieFarmStakingAllowance,
  useNeverdieFarmStakingBalance,
  useNeverdieFarmStakingDepositBalance,
  useNeverdieFarmStakingReward,
  // Dividend
  useDividendStakingAllowance,
  useDividendStakingBalance,
  useDividendStakingDepositBalance,
  useDividendStakingReward
}
