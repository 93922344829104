export const ECR = 80
export const TCR = 80
export const MINT_FEE = 0.03
export const REDEEM_FEE = 0.07

export const MINT_RATIO = {
    USDC: ECR,
    VIB: 100 - ECR,
    ADA: 100 - MINT_FEE
}

export const REDEEM_RATIO = {
    USDC: TCR,
    VIB: 100 - TCR,
    ADA: 100 - MINT_FEE
}