// import { AbiItem } from "web3-utils";
// import PAIR_ABI from "constants/abis/pair-abi.json";
import { useState } from "react";
// import { bignumber } from "mathjs";
import { BigNumber } from "ethers";
import ORACLE_VIB_ABI from "../pages/MintADA/utils/abis/oracleVIB.json";
// import { useActiveWeb3React } from "hooks";
import { ORACLE_VIB_ADDRESS, VIB_ADDRESS } from "pages/MintADA/utils/contracts/address";
import { weiToFixed } from "utils/math-helpers";
import { getWeb3NoAccount } from "utils/web3";
import { AbiItem } from 'web3-utils'

// enum TOKEN_INDEX {
//   token0 = 0,
//   token1 = 1,
// }

// interface ITokenByLp {
//   address: string;
//   tokenPriceIndex: TOKEN_INDEX;
//   token0Decimal: number;
//   token1Decimal: number;
// }

// interface IReserves {
//   _reserve0: string;
//   _reserve1: string;
// }


const useTokenPriceByLp = (): { price: number | null } => {
  const [price, setPrice] = useState<number | null>(null);

  const web3 = getWeb3NoAccount()
  const oracleContract = new web3.eth.Contract(
    (ORACLE_VIB_ABI as unknown) as AbiItem,
    ORACLE_VIB_ADDRESS
  );
  if (!price) {
    oracleContract.methods.consult(VIB_ADDRESS, "1000000000000000000").call()
      .then((result: BigNumber) => {
        const res = weiToFixed(result.toString(), 2);
        setPrice(parseFloat(res));
      }).catch(() => {
        setPrice(0);
      })
  }

  return { price };
};

export default useTokenPriceByLp;
