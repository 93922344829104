import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const languageList = ['en', 'zh-CN', 'jp', 'fr', 'es']

export default function TranslationToggle() {
  const { i18n } = useTranslation()
  return (
    <Dropdown className="transalte-dropdown">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        {(i18n.language ? i18n.language : 'EN').toUpperCase()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {languageList.map((language, i) => (
          <Dropdown.Item key={i} onClick={() => i18n.changeLanguage(`${language}`)}>
            {language.toUpperCase()}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
