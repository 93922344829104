import React, { useEffect, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/swap/styleds'
import { useActiveWeb3React } from '../../hooks'
import './style.css'
import 'react-input-range/lib/css/index.css'
import { useDarkModeManager } from 'state/user/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonConfirmed } from '../../components/Button'
import VIB_ABI from './utils/abis/vib.json'
import USDC_ABI from './utils/abis/usdc.json'
import RAGNAROK_ABI from './utils/abis/ragnarok.json'
import { Contract, BigNumber, ContractInterface } from 'ethers'
import { getProviderOrSigner } from 'utils'
import { ORACLE_VIB_ADDRESS, RAGNAROK_ADDRESS, TOKEN_ADDRESSES, USDC_ADDRESS, VIB_ADDRESS } from './utils/contracts/address'
import isZero from 'utils/isZero'
import { MaxUint256 } from '@ethersproject/constants'
import useTokenPriceByLp from 'hooks/useTokenPriceByLp'
import { ERC20_ABI } from 'constants/abis/erc20'
import mkrMulticall from 'utils/mkrMulticall'
import { TBalanceOfMulticallResult } from './types'
import { weiToFixed } from 'utils/math-helpers'
import { MINT_RATIO } from './utils/constants'
import { toWei } from 'utils/math-helpers'
import web3 from 'utils/web3'
// the position drops below minimum collateral ratio, any user may immediately liquidate the position.
import { AbiItem } from 'web3-utils'
import ORACLE_VIB_ABI from "./utils/abis/oracleVIB.json";
import useSound from 'use-sound';
const mintSound = require("assets/sounds/mint.mp3");
const mintFailSound = require("assets/sounds/playFail.wav");

export default function Mint() {
  const { account, library, chainId } = useActiveWeb3React()
  const [isDark] = useDarkModeManager()
  const [approvalVIB, setApprovalVIB] = useState(false)
  const [approvalUSDC, setApprovalUSDC] = useState(false)
  const [approvalSubmitted, setApprovalSubmitted] = useState(false)
  const [userBalance, setUserBalance] = useState<Array<TBalanceOfMulticallResult>>()
  const [inputBUSD, setInputBUSD] = useState<null | number>(null)
  const [inputVIB, setInputNEVER] = useState<null | number>(null)
  const [output, setOutput] = useState<null | number>(null)

  const { price: vibPrice } = useTokenPriceByLp()
  const [play] = useSound(mintSound);
  const [playFail] = useSound(mintFailSound);

  const getUserBalance = () => {
    if (account) {
      const calls = TOKEN_ADDRESSES.map(st => {
        return {
          address: st.address,
          name: 'balanceOf',
          params: [account]
        }
      })

      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const balanceOfResult = TOKEN_ADDRESSES.map((ta, index) => {
          return {
            tokenData: ta,
            balanceOf: result[index][0] as BigNumber
          }
        }) as TBalanceOfMulticallResult[]
        if (balanceOfResult) setUserBalance(balanceOfResult)
      })
    }
  }

  const checkAllowanceNEVER = async () => {
    if (!account || !library) return
    const tokenContract = new Contract(VIB_ADDRESS, VIB_ABI, getProviderOrSigner(library, account))
    const result: BigNumber = await tokenContract?.allowance(account, RAGNAROK_ADDRESS)
    const hex = result.toHexString()
    if (isZero(hex)) {
      setApprovalVIB(false)
    } else {
      setApprovalVIB(true)
    }
  }

  async function checkAllowanceBUSD() {
    if (!account || !library) return
    const tokenContract = new Contract(USDC_ADDRESS, USDC_ABI, getProviderOrSigner(library, account))
    const result: BigNumber = await tokenContract?.allowance(account, RAGNAROK_ADDRESS)
    const hex = result.toHexString()
    if (isZero(hex)) {
      setApprovalUSDC(false)
    } else {
      setApprovalUSDC(true)
    }
  }

  async function handleApproveClicked(tokenAddress: string, abi: ContractInterface) {
    if (!account || !library) return
    setApprovalSubmitted(true)
    try {
      const tokenContract = new Contract(tokenAddress, abi, getProviderOrSigner(library, account))
      const approveTransaction = await tokenContract?.approve(RAGNAROK_ADDRESS, MaxUint256)
      await approveTransaction.wait()
      checkAllowanceNEVER()
      checkAllowanceBUSD()
    } catch (error) {
      setApprovalSubmitted(false)
    }
  }

  async function handleMintClicked() {
    if (!account || !library || !output || !inputVIB) return playFail()
    const ragnarokContract = new Contract(RAGNAROK_ADDRESS, RAGNAROK_ABI, getProviderOrSigner(library, account))
    try {
      console.info('Mint Btn Clicked: => ', toWei(inputBUSD), toWei(inputVIB * 1.03), toWei(output - output * 0.033))
      const result = await ragnarokContract?.mint(
        toWei(inputBUSD),
        toWei(inputVIB * 1.03),
        toWei(output - output * 0.033)
      )
      result.wait()
      play()
      console.info('Mint result: ', result)
    } catch (error) {
      console.error('Mint result ERROR: ', error)
    }
  }

  function handleinputBUSDType(input: number) {
    setInputBUSD(input)
  }

  function handleInputVIBType(input: number) {
    setInputNEVER(input)
  }

  function handleOutputType(input: number) {
    setOutput(input)
    const oracleContract = new web3.eth.Contract(
      (ORACLE_VIB_ABI as unknown) as AbiItem,
      ORACLE_VIB_ADDRESS
    );
    oracleContract.methods.consult(VIB_ADDRESS, "1000000000000000000").call()
      .then((result: BigNumber) => {
        const res = weiToFixed(result.toString(), 2);
        setInputBUSD(input * (MINT_RATIO.USDC / 100))
        if (res) setInputNEVER((input * (MINT_RATIO.VIB / 100)) / parseFloat(res))
      }).catch(() => {
        console.error('Can not fetch price !')
      })
  }

  // function maxBtnClicked(){
  //   const maxValue = userBalance && userBalance[2] ? parseFloat(weiToFixed(userBalance[2].balanceOf.toString(), 4)) : 0
  //   handleOutputType(maxValue)
  // }

  // function maxBtnClickedBUSD() {
  //   const maxValue = userBalance && userBalance[1] ? parseFloat(weiToFixed(userBalance[1].balanceOf.toString(), 1)) : 0
  //   handleOutputType(maxValue / 0.8)
  // }

  useEffect(() => {
    if (!approvalVIB) checkAllowanceNEVER()
    if (!approvalUSDC) checkAllowanceBUSD()
    if (!userBalance) getUserBalance()
  }, [approvalSubmitted, vibPrice, approvalUSDC, approvalVIB, userBalance])
  return (
    <>
      <SwapPoolTabs active={'swap'} />
      <Wrapper id="swap-page">
        <AutoColumn gap={'md'}>
          <div className="container">
            <div className={`row ${isDark ? `input-panel-wrapper dark` : `input-panel-wrapper`}`}>
              <div className="col-6 header">Input • {MINT_RATIO.USDC + `%`}</div>
              <div className="col-6 header-2 text-truncate text-right pl-0">{`Balance: ${userBalance && userBalance[1] ? weiToFixed(userBalance[1].balanceOf.toString(), 4) : 0
                }`}</div>
              <input
                type="number"
                className="col-5 border-0 collateral-input disabled"
                placeholder={`0.00`}
                value={inputBUSD ? inputBUSD : ``}
                onChange={e => {
                  handleinputBUSDType((e.target.value as unknown) as number)
                }}
                disabled={true}
              />
              <div className="col-7 text-right pr-3 collateral-input-currency">
                {/* {<button type="button" onClick={() => maxBtnClickedBUSD()} className="max-btn mr-3">MAX</button>} */}
                <div className="collateral-base-text d-inline">BUSD</div>
              </div>
            </div>
          </div>

          <AutoColumn justify="space-between" className="text-center">
            <ArrowWrapper clickable>
              <FontAwesomeIcon icon={faPlus} />
            </ArrowWrapper>
          </AutoColumn>

          <div className="container">
            <div className={`row ${isDark ? `input-panel-wrapper dark` : `input-panel-wrapper`}`}>
              <div className="col-6 header">Input • {MINT_RATIO.VIB + `%`}</div>
              <div className="col-6 header-2 text-right">
                Balance: {userBalance && userBalance[0] ? weiToFixed(userBalance[0].balanceOf.toString(), 4) : 0}
              </div>
              <input
                type="number"
                className="col-5 border-0 collateral-input disabled"
                placeholder={`0.00`}
                value={inputVIB ? inputVIB : ``}
                onChange={e => {
                  handleInputVIBType((e.target.value as unknown) as number)
                }}
                disabled={true}
              />
              <div className="col-7 text-right pr-3 collateral-input-currency">
                <div className="collateral-base-text d-inline">NEVER</div>
              </div>
            </div>
          </div>

          <AutoColumn justify="space-between" className="text-center">
            <ArrowWrapper clickable>
              <ArrowDown size="16" />
            </ArrowWrapper>
          </AutoColumn>

          <div className="container">
            <div className={`row ${isDark ? `input-panel-wrapper dark` : `input-panel-wrapper`}`}>
              <div className="col-6 header">Output (estimated)</div>
              <div className="col-6 header-2 text-truncate text-right pl-0">{`Balance: ${userBalance && userBalance[2] ? weiToFixed(userBalance[2].balanceOf.toString(), 4) : 0
                }`}</div>
              <input
                type="number"
                className="col-5 border-0 minted-output"
                placeholder={`0.00`}
                value={output ? output : ``}
                onChange={e => {
                  handleOutputType((e.target.value as unknown) as number)
                }}
                autoFocus={true}
              />
              <div className="col-7 text-right pr-3 collateral-input-currency">
                {/* {<button type="button" onClick={() => maxBtnClicked()} className="max-btn mr-3">MAX</button>} */}
                <div className="collateral-base-text d-inline">EVE</div>
              </div>
            </div>
          </div>
        </AutoColumn>

        <BottomGrouping className="text-center mt-4">
          {!account ? (
            <ButtonLight>Connect Wallet</ButtonLight>
          ) : !approvalUSDC ? (
            <ButtonConfirmed
              onClick={() => handleApproveClicked(USDC_ADDRESS, USDC_ABI)}
              disabled={false}
              width="100%"
              altDisabledStyle={false} // show solid button while waiting
              confirmed={false}
            >{`Approve BUSD`}</ButtonConfirmed>
          ) : !approvalVIB ? (
            <ButtonConfirmed
              onClick={() => handleApproveClicked(VIB_ADDRESS, VIB_ABI)}
              disabled={false}
              width="100%"
              altDisabledStyle={false} // show solid button while waiting
              confirmed={false}
            >{`Approve NEVER`}</ButtonConfirmed>
          ) : (
            <ButtonConfirmed
              onClick={() => handleMintClicked()}
              disabled={false}
              width="100%"
              altDisabledStyle={false} // show solid button while waiting
              confirmed={false}
            >{`Mint`}</ButtonConfirmed>
          )}
        </BottomGrouping>
      </Wrapper>
    </>
  )
}
