// import { ChainId, TokenAmount } from '@uniswap/sdk'
import { ChainId } from '@uniswap/sdk'

import React, { useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo-navbar.svg'
import LogoDark from '../../assets/svg/logo-navbar-dark.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
// import { useETHBalances } from '../../state/wallet/hooks'
import { ExternalLink } from '../../theme'
import { YellowCard } from '../Card'
import Settings from '../Settings'
import { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import Row from 'react-bootstrap/esm/Row'
import useTokenPriceByLp from 'hooks/useTokenPriceByLp'
import './index.css'
import TranslationToggle from './translationToggle'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #111827;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0.5rem 1rem;
    width: calc(100%);
    position: relative;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`
        padding: 1.5rem 1rem;
        padding-right: 3rem !important;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 0.5rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
    position: absolute;
    left: 1rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
   flex-direction: row-reverse;
    align-items: center;
    position: absolute;
    left: -3.5em;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 0rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)}; */
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-left: 4rem;
  `};
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.white};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => 'white'};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const NeverswapDex = styled.div`
  transition: transform 0.3s ease;
  width: auto;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 0.5rem;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 0.85rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin: 0 0.45rem;
      font-size: 0.65rem;
`}
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 0.5rem;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 0.85rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: block;
      margin: 0 0.45rem;
      font-size: 0.65rem;
`}
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.BSC]: 'BSC'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()
  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)

  const { price: vibPrice } = useTokenPriceByLp()
  return (
    <HeaderFrame>
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>

      <HeaderRow>
        <Title href=".">
          <NeverswapDex>
            <img
              src={isDark ? LogoDark : Logo}
              alt="logo"
              style={{ width: `12rem`, paddingBottom: `0.2rem`, marginRight: `0.68rem` }}
            />
          </NeverswapDex>
        </Title>
        <div className="d-none d-md-block">
          <HeaderLinks>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/create') ||
                pathname.startsWith('/find')
              }
            ></StyledNavLink>
            <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
              {t('swap')}
            </StyledNavLink>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/create') ||
                pathname.startsWith('/find')
              }
            >
              {t('pool')}
            </StyledNavLink>
            <StyledNavLink id={`swap-nav-link`} to={'/farm'}>
              {t('farm')}
            </StyledNavLink>
            <StyledNavLink id={`swap-nav-link`} to={'/mint'}>
              {t('mint')}
            </StyledNavLink>
            <StyledExternalLink id={`stake-nav-link`} href={'https://casino.neverswap.com/'}>
              {t('Casino')}
            </StyledExternalLink>
            <StyledExternalLink id={`stake-nav-link`} href={'https://info.neverswap.com/'}>
              {t('info')}
            </StyledExternalLink>
            <StyledExternalLink
              id={`stake-nav-link`}
              href={'https://dex.guru/token/0x1137d5836ef0e0ed9acc74aef8ffe2eaf81120b5-bsc'}
            >
              {t('dexguru')}
            </StyledExternalLink>
            <StyledExternalLink id={`stake-nav-link`} href={'https://docs.neverswap.com/'}>
              {t('docs')}
            </StyledExternalLink>
          </HeaderLinks>
        </div>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall>

          <TranslationToggle />

          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }} className="">
            {account ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                NEVER {vibPrice}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <Settings />
          <div className="d-block d-md-none" style={{ position: 'absolute', right: '2rem' }}>
            <HeaderLinks>
              <StyledNavLink
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
                }
              ></StyledNavLink>

              <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                {t('swap')}
              </StyledNavLink>

              <StyledNavLink
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
                }
              >
                {t('pool')}
              </StyledNavLink>

              <StyledNavLink id={`swap-nav-link`} to={'/farm'}>
                {t('farm')}
              </StyledNavLink>

              <StyledNavLink id={`swap-nav-link`} to={'/mint'}>
                {t('mint')}
              </StyledNavLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://casino.neverswap.com/'}>
                {t('Casino')}
              </StyledExternalLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://info.neverswap.com/'}>
                {t('info')}
              </StyledExternalLink>
              <StyledExternalLink
                id={`stake-nav-link`}
                href={'https://dex.guru/token/0x1137d5836ef0e0ed9acc74aef8ffe2eaf81120b5-bsc'}
              >
                {t('dexguru')}
              </StyledExternalLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://docs.neverswap.com/'}>
                {t('docs')}
              </StyledExternalLink>
            </HeaderLinks>
          </div>
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
