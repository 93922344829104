import { BigNumber } from '@ethersproject/bignumber'

export interface TStakingData {
  token0: string
  token1: string | null
  reward: string
  addLpUrl: string
  removeLpUrl: string
  address: string
  lpAddress: string
  icon: string
  iconReward: string
  poolId: number
  reserveType: reserveType
  group: number
}

export interface TStakingAllowance {
  stakingData: TStakingData
  allowance: BigNumber
}

export interface TStakingBalance {
  stakingData: TStakingData
  balance: BigNumber
}

export interface TStakingDepositBalance {
  stakingData: TStakingData
  balance: BigNumber
}

export interface TStakingReward {
  stakingData: TStakingData
  reward: BigNumber
}

// Dividend types
export interface TStakingDividendData {
  token0: string
  token1: string | null
  reward: string
  addLpUrl: string
  removeLpUrl: string
  address: string
  dividendAddress: string
  icon: string
  iconReward: string
}

export interface IRewardPerBlockResult {
  stakingData: TStakingDividendData
  rewardPerBlock: BigNumber
}

export interface TStakingDividendAllowance {
  stakingData: TStakingDividendData
  allowance: BigNumber
}

export interface TStakingDividendBalance {
  stakingData: TStakingDividendData
  balance: BigNumber
}

export interface TStakingDividendDepositBalance {
  stakingData: TStakingDividendData
  balance: BigNumber
}

export interface TStakingDividendReward {
  stakingData: TStakingDividendData
  reward: BigNumber
}

// APR
export interface IDataResult {
  stakingData: TStakingData
  tvl: number
  apr: number
  lpPrice: number
  rewardPerDay: number
}

export interface IDataDividendResult {
  tvl: number
  rewardPerDay: number
}

// Neverdie
export interface INeverDieDataResult {
  stakingData: TStakingData
  tvl: number
  lpPrice: number
  rewardPerDay: number
}

export interface IStakingSupplyResult {
  stakingData: TStakingData | TStakingDividendData
  stakingSupply: BigNumber
}

export interface ITotalSupplyResult {
  stakingData: TStakingData | TStakingDividendData
  totalSupply: BigNumber
}

export interface IReserves {
  _reserve0: BigNumber
  _reserve1: BigNumber
  _blockTimestampLast: number
}

export interface IGetReservesResult {
  stakingData: TStakingData
  reserves: IReserves | null
}

export interface IRewardPerBlockResult {
  stakingData: TStakingDividendData
  rewardPerBlock: BigNumber
}

export interface ITotalAllocPointResult {
  stakingData: TStakingData
  totalAllocPoint: BigNumber
}

interface IPoolInfo {
  lpToken: string
  allocPoint: BigNumber
  lastRewardBlock: BigNumber
  accNeverPerShare: BigNumber
}

export interface IPoolInfoResult {
  stakingData: TStakingData
  poolInfo: IPoolInfo
}

export enum reserveType {
  reserve0 = 0,
  reserve1 = 1
}
