import React from 'react'
import certikLogo from '../../assets/images/certik-logo.svg'
import logoLite from '../../assets/images/logo-footer.svg'
import twitterLogo from '../../assets/images/twitter-logo.svg'
import telegramLogo from '../../assets/images/telegram-logo.svg'

import './index.css'

export default function Footer() {
  return (
    <>
      <div className={`d-md-flex w-100 mb-sm-0 mb-5 pb-4 px-4 justify-content-between`}>
        <div className="d-sm-flex align-items-center w-100 ">
          <div className="text-center">
            <a
              href="https://www.certik.org/projects/neverswap"
              className="d-flex align-items-center w-100 text-decoration-none justify-content-center"
              target="_blank"
            >
              <div className="d-flex align-items-center cursor-pointer">
                <div className="rounded-circle bg-warning p-2"></div>
              </div>
              <div className="text-white pl-2 pr-3 cursor-pointer" style={{ width: '180px' }}>
                Onboarding Audit by
              </div>
              <img src={certikLogo} alt="" className="cursor-pointer" style={{ height: `1.68rem` }} />
            </a>
          </div>
          <div className="justify-content-center d-flex my-sm-0 my-3 mb-4 mb-sm-0">
            <div className="ml-3">
              <a href="https://twitter.com/neverswap1" target="_blank">
                <img src={twitterLogo} alt="" className="cursor-pointer" style={{ height: `1.88rem` }} />
              </a>
            </div>
            <div className="ml-3">
              <a href="https://t.me/neverswapcom" target="_blank">
                <img src={telegramLogo} alt="" className="cursor-pointer" style={{ height: `1.88rem` }} />
              </a>
            </div>
          </div>
        </div>
        <img src={logoLite} className="d-none d-sm-block" alt="" style={{ height: `1.28rem` }} />
      </div>
    </>
  )
}
