import React, { useEffect, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/swap/styleds'
import { useActiveWeb3React } from '../../hooks'
import './style.css'
import 'react-input-range/lib/css/index.css'
import { useDarkModeManager } from 'state/user/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ButtonConfirmed } from '../../components/Button'
// import VIB_ABI from './utils/abis/vib.json'
import ADA_ABI from './utils/abis/ada.json'
import { Contract, BigNumber, ContractInterface } from 'ethers'
import { getProviderOrSigner } from 'utils'
import { RAGNAROK_ADDRESS, ADA_ADDRESS, TOKEN_ADDRESSES, ORACLE_VIB_ADDRESS, VIB_ADDRESS } from './utils/contracts/address'
import isZero from 'utils/isZero'
import { MaxUint256 } from '@ethersproject/constants'
// import useTokenPriceByLp from 'hooks/useTokenPriceByLp'
import { TBalanceOfMulticallResult } from './types'
import { ERC20_ABI } from 'constants/abis/erc20'
import { toWei, weiToFixed } from 'utils/math-helpers'
import mkrMulticall from 'utils/mkrMulticall'
import { REDEEM_RATIO } from './utils/constants'
import RAGNAROK_ABI from './utils/abis/ragnarok.json'
import web3 from 'utils/web3'
import ORACLE_VIB_ABI from "./utils/abis/oracleVIB.json";
import { AbiItem } from 'web3-utils';
import useSound from 'use-sound';
const redeemSound = require("assets/sounds/redeem.wav");
const redeemFailSound = require("assets/sounds/playFail.wav");
// the position drops below minimum collateral ratio, any user may immediately liquidate the position.

export default function Mint() {
  const { account, library, chainId } = useActiveWeb3React()
  const [isDark] = useDarkModeManager()
  // const [approvalVIB, setApprovalVIB] = useState(false)
  const [approvalADA, setApprovalADA] = useState(false)
  const [approvalSubmitted, setApprovalSubmitted] = useState(false)
  const [userBalance, setUserBalance] = useState<Array<TBalanceOfMulticallResult>>()
  const [inputADA, setInputADA] = useState<null | number>(null)
  const [outputVIB, setOutputVIB] = useState<null | number>(null)
  const [outputUSDC, setOutputUSDC] = useState<null | number>(null)

  const [play] = useSound(redeemSound);
  const [playFail] = useSound(redeemFailSound);

  // ================ START TO BE MOVED TO HOOK ================

  async function getUserBalance() {
    if (account) {
      const calls = TOKEN_ADDRESSES.map(st => {
        return {
          address: st.address,
          name: 'balanceOf',
          params: [account]
        }
      })

      mkrMulticall(chainId, ERC20_ABI, calls).then(result => {
        const balanceOfResult = TOKEN_ADDRESSES.map((ta, index) => {
          return {
            tokenData: ta,
            balanceOf: result[index][0] as BigNumber
          }
        }) as TBalanceOfMulticallResult[]
        if (balanceOfResult) setUserBalance(balanceOfResult)
      })
    }
  }

  async function checkAllowanceADA() {
    if (!account || !library) return
    const tokenContract = new Contract(ADA_ADDRESS, ADA_ABI, getProviderOrSigner(library, account))
    const result: BigNumber = await tokenContract?.allowance(account, RAGNAROK_ADDRESS)
    const hex = result.toHexString()
    if (isZero(hex)) {
      setApprovalADA(false)
    } else {
      setApprovalADA(true)
    }
  }
  // ================ END TO BE MOVED TO HOOK ================

  async function handleApproveClicked(tokenAddress: string, abi: ContractInterface) {
    if (!account || !library) return
    setApprovalSubmitted(true)
    try {
      const tokenContract = new Contract(tokenAddress, abi, getProviderOrSigner(library, account))
      const approveTransaction = await tokenContract?.approve(RAGNAROK_ADDRESS, MaxUint256)
      await approveTransaction.wait()
      checkAllowanceADA()
    } catch (error) {
      setApprovalSubmitted(false)
    }
  }

  async function handleRedeemClicked() {
    if (!account || !library || !outputUSDC || !outputVIB) return playFail()
    const ragnarokContract = new Contract(RAGNAROK_ADDRESS, RAGNAROK_ABI, getProviderOrSigner(library, account))
    try {
      console.info(
        'Redeem Clicked: => ',
        toWei(inputADA),
        toWei(outputVIB * (3.7 / 100)),
        toWei(outputUSDC * (3.7 / 100))
      )
      const result = await ragnarokContract?.redeem(
        toWei(inputADA),
        toWei(outputVIB * (3.7 / 100)),
        toWei(outputUSDC * (3.7 / 100))
      )
      result.wait()
      play()
      console.info('Redeem result: ', result)
    } catch (error) {
      console.error('Redeem result ERROR: ', error)
    }
  }

  function handleInputADAType(input: number) {
    setInputADA(input)
    const oracleContract = new web3.eth.Contract(
      (ORACLE_VIB_ABI as unknown) as AbiItem,
      ORACLE_VIB_ADDRESS
    );
    oracleContract.methods.consult(VIB_ADDRESS, "1000000000000000000").call()
      .then((result: BigNumber) => {
        const res = weiToFixed(result.toString(), 2);
        setOutputUSDC(input * (REDEEM_RATIO.USDC / 100))
        if (res) setOutputVIB((input * (REDEEM_RATIO.VIB / 100)) / parseFloat(res))
      }).catch(() => {
        console.error('Can not fetch price !')
      })
  }

  function maxBtnClicked() {
    const maxValue = userBalance && userBalance[2] ? parseFloat(weiToFixed(userBalance[2].balanceOf.toString(), 4)) : 0
    handleInputADAType(maxValue)
  }

  useEffect(() => {
    if (!approvalADA) checkAllowanceADA()
    if (!userBalance) getUserBalance()
  }, [approvalSubmitted, approvalADA, userBalance])
  return (
    <>
      <SwapPoolTabs active={'swap'} />
      <Wrapper id="swap-page">
        <AutoColumn gap={'md'}>
          <div className="container">
            <div className={`row ${isDark ? `input-panel-wrapper dark` : `input-panel-wrapper`}`}>
              <div className="col-4 header">Input</div>
              <div className="col-8 header-2 text-truncate text-right pl-0">{`Balance: ${userBalance && userBalance[2] ? weiToFixed(userBalance[2].balanceOf.toString(), 4) : 0
                }`}</div>
              <input
                type="number"
                className="col-5 border-0 collateral-input"
                placeholder={`0.00`}
                value={inputADA ? inputADA : ``}
                onChange={e => {
                  handleInputADAType((e.target.value as unknown) as number)
                }}
                autoFocus={true}
              />
              <div className="col-7 text-right pr-3 collateral-input-currency">
                {<button type="button" onClick={() => maxBtnClicked()} className="max-btn mr-3">MAX</button>}
                <div className="collateral-base-text d-inline">EVE</div>
              </div>
            </div>
          </div>

          <AutoColumn justify="space-between" className="text-center">
            <ArrowWrapper clickable>
              <ArrowDown size="16" />
            </ArrowWrapper>
          </AutoColumn>

          <div className="container">
            <div className={`row ${isDark ? `input-panel-wrapper dark` : `input-panel-wrapper`}`}>
              <div className="col-6 header">Output • {REDEEM_RATIO.VIB + `%`}</div>
              <div className="col-6 header-2 text-right">
                Balance: {`${userBalance && userBalance[0] ? weiToFixed(userBalance[0].balanceOf.toString(), 4) : 0}`}
              </div>
              <input
                type="number"
                className="col-5 border-0 collateral-input disabled"
                placeholder={`0.00`}
                value={outputVIB ? outputVIB : ``}
                onChange={e => console.error(e)}
                disabled={true}
              />
              <div className="col-7 text-right pr-3 collateral-input-currency">
                <div className="collateral-base-text d-inline">NEVER</div>
              </div>
            </div>
          </div>

          <AutoColumn justify="space-between" className="text-center">
            <ArrowWrapper clickable>
              <FontAwesomeIcon icon={faPlus} />
            </ArrowWrapper>
          </AutoColumn>

          <div className="container">
            <div className={`row ${isDark ? `input-panel-wrapper dark` : `input-panel-wrapper`}`}>
              <div className="col-6 header">Output • {REDEEM_RATIO.USDC + `%`}</div>
              <div className="col-6 header-2 text-truncate text-right pl-0">{`Balance: ${userBalance && userBalance[1] ? weiToFixed(userBalance[1].balanceOf.toString(), 4) : 0
                }`}</div>
              <input
                type="number"
                className="col-5 border-0 collateral-input disabled"
                placeholder={`0.00`}
                value={outputUSDC ? outputUSDC : ``}
                onChange={e => console.error(e)}
                disabled={true}
              />
              <div className="col-7 text-right pr-3 collateral-input-currency">
                <div className="collateral-base-text d-inline">BUSD</div>
              </div>
            </div>
          </div>
        </AutoColumn>

        <BottomGrouping className="text-center mt-4">
          {!account ? (
            <ButtonLight>Connect Wallet</ButtonLight>
          ) : !approvalADA ? (
            <ButtonConfirmed
              onClick={() => handleApproveClicked(ADA_ADDRESS, ADA_ABI)}
              disabled={false}
              width="100%"
              altDisabledStyle={false} // show solid button while waiting
              confirmed={false}
            >{`Approve EVE`}</ButtonConfirmed>
          ) : (
            <ButtonConfirmed
              onClick={() => handleRedeemClicked()}
              disabled={false}
              width="100%"
              altDisabledStyle={false} // show solid button while waiting
              confirmed={false}
            >{`Redeem`}</ButtonConfirmed>
          )}
        </BottomGrouping>
      </Wrapper>
    </>
  )
}
