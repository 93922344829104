import React, { useState } from 'react'
import './farm.css'
import { useDarkModeManager } from 'state/user/hooks'
import { IDataResult, TStakingData, INeverDieDataResult, IDataDividendResult, TStakingDividendData } from './types'
import { STAKING_LIST, NEVERDIE_STAKING_LIST, DIVIDEND_STAKING_LIST, NEVERDIE_FARM } from './constants'
import tvlBg from '../../assets/images/tvl-bg.svg'
// import adamantiumIcon from '../../assets/images/farm/ADAMANTIUM.png'
import FAIRLAUNCH_ABI from 'constants/abis/staking/fairlaunch.json'
import DIVIDEND_ABI from 'constants/abis/staking/dividend.json'
import { MaxUint256 } from '@ethersproject/constants'

// import { ChainId } from '@uniswap/sdk'
import Modal from 'components/Modal'
import {
  useDividendStakingAllowance,
  useDividendStakingBalance,
  useDividendStakingDepositBalance,
  useDividendStakingReward,
  useNeverdieFarmStakingAllowance,
  useNeverdieFarmStakingBalance,
  useNeverdieFarmStakingDepositBalance,
  useNeverdieFarmStakingReward,
  useNeverFarmStakingAllowance,
  useNeverFarmStakingBalance,
  useNeverFarmStakingDepositBalance,
  useNeverFarmStakingReward
} from './hooks'
import { BigNumber } from 'ethers'
// import { BigNumber as BigNumberMike } from 'bignumber.js'
import LP_ABI from 'constants/abis/staking/lp.json'
import ERC20_ABI from 'constants/abis/erc20.json'
import { useContract } from 'components/SearchModal/MintFunction'
// import { useActiveWeb3React } from 'hooks'
import { useWeiAmount } from 'hooks/useWeiAmount'
import { weiToFixed } from 'utils/math-helpers'
import { usePoolData, useNeverDiePoolData, useDividendPoolData } from './poolHooks'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { numberWithCommas, numberWithCommasZero } from 'utils/numberUtils'

import casinoBanner from '../../assets/images/farm/casino_banner.gif'

import useSound from 'use-sound'
const claimSound = require('assets/sounds/cash-register.mp3')

// APY = (1 + r/n)^n – 1
// const aprToApy = (apr: number) => {
//   const n = 12
//   const aprBN = new BigNumberMike(apr.toFixed(0))
//   const oneBN = new BigNumberMike(1)
//   const nBN = new BigNumberMike(n)

//   const result = oneBN
//     .plus(aprBN.div(nBN))
//     .pow(nBN)
//     .minus(oneBN)

//   return result.gte(9999999999)
//     ? Infinity
//     : result.toFormat({
//         // string to prepend
//         prefix: '',
//         // decimal separator
//         decimalSeparator: '.',
//         // grouping separator of the integer part
//         groupSeparator: ',',
//         // primary grouping size of the integer part
//         groupSize: 3,
//         // secondary grouping size of the integer part
//         secondaryGroupSize: 0,
//         // grouping separator of the fraction part
//         fractionGroupSeparator: ' ',
//         // grouping size of the fraction part
//         fractionGroupSize: 0,
//         // string to append
//         suffix: ''
//       })
// }

// APY = (1 + r/n)^n – 1
// const aprToApy = (apr: number) => {
//   const n = 12

//   return Math.pow(1 + apr / n, n) - 1
// }

export default function FarmPage() {
  const [isDark] = useDarkModeManager()

  const { allowanceResult: neverFarmAllowanceResult, update: updateNeverFarmAllowance } = useNeverFarmStakingAllowance()
  const { balanceResult: neverFramBalanceResult, update: updateNeverFarmBalance } = useNeverFarmStakingBalance()
  const {
    depositBalanceResult: neverFramDepositBalanceResult,
    update: updateNeverFarmDepositBalance
  } = useNeverFarmStakingDepositBalance()
  const {
    stakingRewardResult: neverFarmStakingRewardResult,
    update: updateNeverFarmStakingReward
  } = useNeverFarmStakingReward()

  //

  const {
    allowanceResult: neverdieFarmAllowanceResult,
    update: updateNeverdieFarmAllowance
  } = useNeverdieFarmStakingAllowance()
  const {
    balanceResult: neverdieFramBalanceResult,
    update: updateNeverdieFarmBalance
  } = useNeverdieFarmStakingBalance()
  const {
    depositBalanceResult: neverdieFramDepositBalanceResult,
    update: updateNeverdieFarmDepositBalance
  } = useNeverdieFarmStakingDepositBalance()
  const {
    stakingRewardResult: neverdieFarmStakingRewardResult,
    update: updateNeverdieFarmStakingReward
  } = useNeverdieFarmStakingReward()

  const { allowanceResult: dividendAllowanceResult, update: updateDividendAllowance } = useDividendStakingAllowance()
  const { balanceResult: dividendBalanceResult, update: updateDividendBalance } = useDividendStakingBalance()
  const {
    depositBalanceResult: dividenDdepositBalanceResult,
    update: updateDividendDepositBalance
  } = useDividendStakingDepositBalance()
  const {
    stakingRewardResult: dividendStakingRewardResult,
    update: updateDividendStakingReward
  } = useDividendStakingReward()

  const [totalTVL, setTotalTVL] = useState<number>(0)

  const { poolData } = usePoolData()

  const { poolData: neverDiePoolData } = useNeverDiePoolData()

  const { poolData: dividendPoolData } = useDividendPoolData()

  const updateAllData = useCallback(() => {
    // Never
    updateNeverFarmAllowance()
    updateNeverFarmBalance()
    updateNeverFarmDepositBalance()
    updateNeverFarmStakingReward()
    // Neverdie
    updateNeverdieFarmAllowance()
    updateNeverdieFarmBalance()
    updateNeverdieFarmDepositBalance()
    updateNeverdieFarmStakingReward()
    // Dividend
    updateDividendAllowance()
    updateDividendBalance()
    updateDividendDepositBalance()
    updateDividendStakingReward()
  }, [])

  useEffect(() => {
    setInterval(() => {
      updateAllData()
    }, 10000)
  }, [])

  useEffect(() => {
    if (poolData.length && neverDiePoolData.length) {
      let totalTvlSum = 0
      poolData.forEach(pd => {
        totalTvlSum += pd.tvl
      })
      neverDiePoolData.forEach(pd => {
        totalTvlSum += pd.tvl
      })
      setTotalTVL(totalTvlSum)
    }
  }, [poolData, neverDiePoolData])

  return (
    <>
      <div className={`${isDark ? 'dark' : 'light'} container farm px-0`}>
        <div className="farming-container w-100">
          <div className="row">
            <div className="col-12 col-sm-5 text-center pt-5">
              <div className="d-flex mb-3 pb-3">
                <img src={tvlBg} style={{ width: `5rem` }} className="mr-4" alt="" />
                <div className="pt-4">
                  <h6 className="text-tvl-title text-left">TOTAL VALUE LOCKED</h6>
                  <h2 className="mb-4 text-tvl text-left">${numberWithCommas(totalTVL.toFixed(2))}</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-7 text-center ">
              <div className="d-flex pb-4">
                <a href="https://casino.neverswap.com/">
                  <img src={casinoBanner} style={{ width: `100%` }} alt="" />
                </a>
              </div>
            </div>
          </div>
          {/* GROUP DIVIDEND : NEVER TOKENS */}
          <h5 className="text-tvl-title text-left">DIVIDEND</h5>
          <div className="farming-title row mb-4 text-left d-none d-sm-flex">
            <div className="col-3">Asset</div>
            <div className="col-3">Rewards</div>
            <div className="col-2">Deposited</div>
            <div className="col-2">TVL</div>
            <div className="col-2"></div>
          </div>
          <div className="farming-list mb-5">
            {DIVIDEND_STAKING_LIST.map((sl, i) => (
              <div key={i}>
                <StakingItemDividend
                  stakingDataItem={sl}
                  allowance={dividendAllowanceResult.length ? dividendAllowanceResult[i].allowance : BigNumber.from(0)}
                  balance={dividendBalanceResult.length ? dividendBalanceResult[i].balance : BigNumber.from(0)}
                  depositBalance={
                    dividenDdepositBalanceResult.length ? dividenDdepositBalanceResult[i].balance : BigNumber.from(0)
                  }
                  stakingReward={
                    dividendStakingRewardResult.length ? dividendStakingRewardResult[i].reward : BigNumber.from(0)
                  }
                  updateAllData={updateAllData}
                  data={dividendPoolData[i]}
                />
              </div>
            ))}
          </div>
          {/* GROUP 0 : NEVER TOKENS */}
          <h5 className="text-tvl-title text-left ">
            NEVERDIE Farms <span className="h6"> ( 4% withdraw fees )</span>
          </h5>
          <div className="farming-title row mb-4 text-left d-none d-sm-flex">
            <div className="col-3">Asset</div>
            <div className="col-3">Rewards</div>
            <div className="col-2">Deposited</div>
            <div className="col-2">TVL</div>
            <div className="col-2"></div>
          </div>
          <div className="farming-list mb-5">
            {NEVERDIE_STAKING_LIST.map((sl, i) =>
              sl.group === 1 ? (
                <div key={i}>
                  <NeverDieStakingItem
                    stakingDataItem={sl}
                    allowance={
                      neverdieFarmAllowanceResult.length ? neverdieFarmAllowanceResult[i].allowance : BigNumber.from(0)
                    }
                    balance={
                      neverdieFramBalanceResult.length ? neverdieFramBalanceResult[i].balance : BigNumber.from(0)
                    }
                    depositBalance={
                      neverdieFramDepositBalanceResult.length
                        ? neverdieFramDepositBalanceResult[i].balance
                        : BigNumber.from(0)
                    }
                    stakingReward={
                      neverdieFarmStakingRewardResult.length
                        ? neverdieFarmStakingRewardResult[i].reward
                        : BigNumber.from(0)
                    }
                    updateAllData={updateAllData}
                    data={neverDiePoolData[i]}
                  />
                </div>
              ) : null
            )}
          </div>

          {/* GROUP 1 : NEVER TOKENS */}
          <h5 className="text-tvl-title text-left">NEVER Tokens</h5>
          <div className="farming-title row mb-4 text-left d-none d-sm-flex">
            <div className="col-3">Asset</div>
            <div className="col-3">Rewards</div>
            <div className="col-2">Deposited</div>
            <div className="col-2">TVL</div>
            <div className="col-2">Rates</div>
          </div>
          <div className="farming-list mb-5">
            {STAKING_LIST.map((sl, i) =>
              sl.group === 1 ? (
                <div key={i}>
                  <StakingItem
                    stakingDataItem={sl}
                    allowance={
                      neverFarmAllowanceResult.length ? neverFarmAllowanceResult[i].allowance : BigNumber.from(0)
                    }
                    balance={neverFramBalanceResult.length ? neverFramBalanceResult[i].balance : BigNumber.from(0)}
                    depositBalance={
                      neverFramDepositBalanceResult.length
                        ? neverFramDepositBalanceResult[i].balance
                        : BigNumber.from(0)
                    }
                    stakingReward={
                      neverFarmStakingRewardResult.length ? neverFarmStakingRewardResult[i].reward : BigNumber.from(0)
                    }
                    updateAllData={updateAllData}
                    data={poolData[i]}
                  />
                </div>
              ) : null
            )}
          </div>
          {/* GROUP 2 : STABLE TOKENS */}
          <h5 className="text-tvl-title text-left">STABLE Tokens</h5>
          <div className="farming-title row mb-4 text-left d-none d-sm-flex">
            <div className="col-3">Asset</div>
            <div className="col-3">Rewards</div>
            <div className="col-2">Deposited</div>
            <div className="col-2">TVL</div>
            <div className="col-2">Rates</div>
          </div>
          <div className="farming-list mb-5">
            {STAKING_LIST.map((sl, i) =>
              sl.group === 2 ? (
                <div key={i}>
                  <StakingItem
                    stakingDataItem={sl}
                    allowance={
                      neverFarmAllowanceResult.length ? neverFarmAllowanceResult[i].allowance : BigNumber.from(0)
                    }
                    balance={neverFramBalanceResult.length ? neverFramBalanceResult[i].balance : BigNumber.from(0)}
                    depositBalance={
                      neverFramDepositBalanceResult.length
                        ? neverFramDepositBalanceResult[i].balance
                        : BigNumber.from(0)
                    }
                    stakingReward={
                      neverFarmStakingRewardResult.length ? neverFarmStakingRewardResult[i].reward : BigNumber.from(0)
                    }
                    updateAllData={updateAllData}
                    data={poolData[i]}
                  />
                </div>
              ) : null
            )}
          </div>
          {/* GROUP 3 : STABLE TOKENS */}
          <h5 className="text-tvl-title text-left">OTHER Tokens</h5>
          <div className="farming-title row mb-4 text-left d-none d-sm-flex">
            <div className="col-3">Asset</div>
            <div className="col-3">Rewards</div>
            <div className="col-2">Deposited</div>
            <div className="col-2">TVL</div>
            <div className="col-2">Rates</div>
          </div>
          <div className="farming-list mb-5">
            {STAKING_LIST.map((sl, i) =>
              sl.group === 3 ? (
                <div key={i}>
                  <StakingItem
                    stakingDataItem={sl}
                    allowance={
                      neverFarmAllowanceResult.length ? neverFarmAllowanceResult[i].allowance : BigNumber.from(0)
                    }
                    balance={neverFramBalanceResult.length ? neverFramBalanceResult[i].balance : BigNumber.from(0)}
                    depositBalance={
                      neverFramDepositBalanceResult.length
                        ? neverFramDepositBalanceResult[i].balance
                        : BigNumber.from(0)
                    }
                    stakingReward={
                      neverFarmStakingRewardResult.length ? neverFarmStakingRewardResult[i].reward : BigNumber.from(0)
                    }
                    updateAllData={updateAllData}
                    data={poolData[i]}
                  />
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface StakingItemProps {
  stakingDataItem: TStakingData
  allowance: BigNumber
  balance: BigNumber
  depositBalance: BigNumber
  stakingReward: BigNumber
  updateAllData: () => void
  data: IDataResult
}

interface StakingItemDividendProps {
  stakingDataItem: TStakingDividendData
  allowance: BigNumber
  balance: BigNumber
  depositBalance: BigNumber
  stakingReward: BigNumber
  updateAllData: () => void
  data: IDataDividendResult
}

const StakingItemDividend = (props: StakingItemDividendProps) => {
  const [toggleShow, setToggleShow] = useState(false)
  // const [weight, setWeight] = useState('0')
  const [depositInput, setDepositInput] = useState('')
  const [withdrawInput, setWithdrawInput] = useState('')
  const depositWei = useWeiAmount(depositInput)
  const withdrawWei = useWeiAmount(withdrawInput)
  const [isDark] = useDarkModeManager()

  const [playClaim] = useSound(claimSound)

  const dividendContract = useContract(props.stakingDataItem.dividendAddress, DIVIDEND_ABI, true)
  const tokenContract = useContract(props.stakingDataItem.address, ERC20_ABI, true)

  const [loading, setLoading] = useState(false)

  const approve = async () => {
    const transaction = await tokenContract?.approve(props.stakingDataItem.dividendAddress, MaxUint256)
    await transaction.wait()
    props.updateAllData()
  }
  const deposit = async () => {
    const transaction = await dividendContract?.deposit(depositWei)
    await transaction.wait()
    props.updateAllData()
  }
  const withdraw = async () => {
    const transaction = await dividendContract?.withdraw(withdrawWei)
    await transaction.wait()
    props.updateAllData()
  }
  const claimReward = async () => {
    const transaction = await dividendContract?.deposit(BigNumber.from(0))
    await transaction.wait()
    playClaim()
    props.updateAllData()
  }
  const disableDeposit = !(
    depositInput &&
    BigNumber.from(depositWei).gt(0) &&
    props.balance.gte(BigNumber.from(depositWei)) &&
    !props.allowance.isZero()
  )
  const disableWithdraw = !(
    withdrawInput &&
    BigNumber.from(withdrawWei).gt(0) &&
    props.depositBalance.gte(BigNumber.from(withdrawWei))
  )
  const disableClaim = props.stakingReward.isZero()

  //

  const handleApproveBtn = async () => {
    setLoading(true)
    await approve().finally(() => {
      setLoading(false)
    })
  }

  const handleDepositBtn = async () => {
    setLoading(true)
    await deposit().finally(() => {
      setLoading(false)
    })
    setDepositInput('')
  }

  const handleWithdrawBtn = async () => {
    setLoading(true)
    await withdraw().finally(() => {
      setLoading(false)
    })
    setWithdrawInput('')
  }

  const handleClaimBtn = async () => {
    setLoading(true)
    await claimReward().finally(() => {
      setLoading(false)
    })
  }

  const handleMaxDepositBtn = () => {
    setDepositInput(weiToFixed(props.balance.toString(), 18))
  }

  const handleMaxWithdrawBtn = () => {
    setWithdrawInput(weiToFixed(props.depositBalance.toString(), 18))
  }

  return (
    <>
      <div className={`farming-item ${toggleShow ? 'active' : ''} mb-4`}>
        <Modal
          isOpen={loading}
          onDismiss={() => {
            //
          }}
          maxHeight={90}
        >
          <div className={`modal-loading ${isDark ? 'text-white' : 'text-black'}`}>
            <div className="text-center">
              <div className="spinner-border text-primary mx-auto mb-4" role="status"></div>
              <div className="ml-3">Waiting for Transaction...</div>
            </div>
          </div>
        </Modal>
        <div className="farming-header row" onClick={() => setToggleShow(!toggleShow)}>
          <div className="tokens-group col-6 col-sm-3 d-flex">
            {props.stakingDataItem.token1 === null ? (
              <>
                {/* single token */}
                <div className="pl-sm-0 pl-3 d-flex align-items-center">
                  <div className="img-logo mr-4">
                    <img src={props.stakingDataItem.icon} height="46px" alt="" />
                  </div>
                  <div className={`title-group`}>
                    <div className="text-title">{props.stakingDataItem.token0}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* pair token */}
                <div className="pl-sm-0 pl-3 d-flex align-items-center">
                  <div className="img-logo">
                    <img src={props.stakingDataItem.icon} width="72px" alt="" />
                  </div>
                  <div className={`title-group`}>
                    <div className="text-title">{props.stakingDataItem.token0}</div>
                    <div className="text-title">{props.stakingDataItem.token1}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="tokens-group col-6 col-sm-3 d-flex">
            <div className="d-flex align-items-center">
              <div className="img-logo">
                <img src={props.stakingDataItem.iconReward} width="42px" alt="" />
              </div>
              <div className={`title-group`}>
                <div className="text-title">{props.stakingDataItem.reward}</div>
                <div className="text-sub">
                  {/* {props.data ? numberWithCommasZero(props.data.rewardPerDay.toFixed(2)) : '-'}{' '}
                  {props.stakingDataItem.reward} per day */}
                </div>
              </div>
            </div>
          </div>

          <div className="deposited-data col-sm-2 col-3 d-sm-flex d-none">
            {props.depositBalance.isZero()
              ? '0'
              : numberWithCommas(parseFloat(weiToFixed(props.depositBalance.toString(), 2)).toString())}
          </div>
          <div className="tvl-data col-sm-2 col-3 d-sm-flex d-none">
            {props.data ? numberWithCommas(props?.data?.tvl?.toFixed(2)) : '...'}
          </div>

          <div className="apr-data col-sm-2 col-6  d-sm-none d-flex">
            <div>
              <div className="">
                <span className="title">TVL</span>{' '}
                <span className="value">{numberWithCommas(props?.data?.tvl?.toFixed(2))}</span>
              </div>
              <span className="title">Deposited</span>{' '}
              <span className="value">
                {props.depositBalance.isZero()
                  ? '0'
                  : numberWithCommas(parseFloat(weiToFixed(props.depositBalance.toString(), 2)).toString())}
              </span>
            </div>
          </div>

          <div className="apr-data col-sm-2 col-6">
            <div>
              <div className="">
                <span className="title"></span> <span className="value"></span>
              </div>
              <span className="title"></span> <span className="value"></span>
              {/* <span className="value">{props?.data?.apr !== Infinity ? aprToApy(props?.data?.apr) : 'Infinity'}%</span> */}
            </div>
          </div>
        </div>
        <hr className="farming-divider" />
        <div className="farming-body d-block">
          <div className="row">
            <div className="col-12 d-block">
              <div className="row">
                <div className="col-12 px-4 px-sm-3 col-sm-6 mt-2 mt-sm-0 ">
                  <div className="w-100">
                    Balance:{' '}
                    <span className="text-balance">
                      {props.balance.isZero() ? '0' : weiToFixed(props.balance.toString(), 2)}
                    </span>
                  </div>
                  <div className="w-100">
                    <input
                      type="number"
                      placeholder="0.00"
                      className="farming-input"
                      value={depositInput}
                      onChange={e => setDepositInput(e.target.value)}
                    />
                    <button onClick={handleMaxDepositBtn} className="btn-max">
                      Max
                    </button>
                  </div>
                  <div className="d-flex gap-1">
                    <button
                      onClick={handleApproveBtn}
                      className={`btn-farming-confirm mb-sm-0 mb-4 ${!props.allowance.isZero() ? 'd-none' : ''}`}
                    >
                      Approve
                    </button>
                    <button
                      onClick={handleDepositBtn}
                      className={`btn-farming-confirm mb-sm-0 mb-4 ${disableDeposit ? 'disabled' : ''}`}
                      disabled={disableDeposit}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
                <div className="col-12 px-4 px-sm-3 col-sm-6">
                  <div className="w-100">
                    Deposited:{' '}
                    <span className="text-balance">
                      {props.depositBalance.isZero() ? '0' : weiToFixed(props.depositBalance.toString(), 2)}
                    </span>
                  </div>
                  <div className="w-100">
                    <input
                      type="number"
                      placeholder="0.00"
                      className="farming-input"
                      value={withdrawInput}
                      onChange={e => setWithdrawInput(e.target.value)}
                    />
                    <button onClick={handleMaxWithdrawBtn} className="btn-max ">
                      Max
                    </button>
                  </div>
                  <div className="w-100">
                    <button
                      onClick={handleWithdrawBtn}
                      className={`btn-farming-confirm btn-block ${disableWithdraw ? 'disabled' : ''}`}
                      disabled={disableWithdraw}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="farming-divider" />
        <div className="farming-footer">
          <div className="text-liquidity d-sm-flex d-none"></div>
          <div className="reward">
            <div className="title">Reward:</div>
            <div className="value">
              {props.stakingReward.isZero() ? '0.00' : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
            </div>
            <div className="desc">{props.stakingDataItem.reward}</div>
            <button
              onClick={handleClaimBtn}
              disabled={disableClaim}
              className={`d-sm-block d-none btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
            >
              Claim
            </button>
          </div>
          <button
            onClick={handleClaimBtn}
            disabled={disableClaim}
            className={`d-sm-none d-block  btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
          >
            Claim
          </button>
          <div className="text-liquidity d-sm-none d-flex">
            <a href={props.stakingDataItem.addLpUrl}>Add liquidity</a>
            <a href={props.stakingDataItem.removeLpUrl}>Remove liquidity</a>
          </div>
        </div>
      </div>
    </>
  )
}

const StakingItem = (props: StakingItemProps) => {
  const [toggleShow, setToggleShow] = useState(false)
  // const [weight, setWeight] = useState('0')
  const [depositInput, setDepositInput] = useState('')
  const [withdrawInput, setWithdrawInput] = useState('')
  const depositWei = useWeiAmount(depositInput)
  const withdrawWei = useWeiAmount(withdrawInput)
  const [isDark] = useDarkModeManager()

  const [playClaim] = useSound(claimSound)

  const fairlaunchAddress = '0x8fb60dd3557c491e04d00a06fdc0618423a3c618'
  const fairlaunchContract = useContract(fairlaunchAddress, FAIRLAUNCH_ABI, true)
  const lpContract = useContract(
    props.stakingDataItem.token1 !== null ? props.stakingDataItem.lpAddress : props.stakingDataItem.address,
    LP_ABI,
    true
  )

  const [loading, setLoading] = useState(false)

  const approve = async () => {
    const transaction = await lpContract?.approve(fairlaunchAddress, MaxUint256)
    await transaction.wait()
    props.updateAllData()
  }
  const deposit = async () => {
    if (props.stakingDataItem.poolId === 0) {
      const transaction = await fairlaunchContract?.enterStaking(depositWei)
      await transaction.wait()
      props.updateAllData()
    } else {
      const transaction = await fairlaunchContract?.deposit(props.stakingDataItem.poolId, depositWei)
      await transaction.wait()
      props.updateAllData()
    }
  }
  const withdraw = async () => {
    if (props.stakingDataItem.poolId === 0) {
      const transaction = await fairlaunchContract?.leaveStaking(withdrawWei)
      await transaction.wait()
      props.updateAllData()
    } else {
      const transaction = await fairlaunchContract?.withdraw(props.stakingDataItem.poolId, withdrawWei)
      await transaction.wait()
      props.updateAllData()
    }
  }
  const claimReward = async () => {
    const transaction = await fairlaunchContract?.deposit(props.stakingDataItem.poolId, BigNumber.from(0))
    await transaction.wait()
    playClaim()
    props.updateAllData()
  }
  const disableDeposit = !(
    depositInput &&
    BigNumber.from(depositWei).gt(0) &&
    props.balance.gte(BigNumber.from(depositWei)) &&
    !props.allowance.isZero()
  )
  const disableWithdraw = !(
    withdrawInput &&
    BigNumber.from(withdrawWei).gt(0) &&
    props.depositBalance.gte(BigNumber.from(withdrawWei))
  )
  const disableClaim = props.stakingReward.isZero()

  //

  const handleApproveBtn = async () => {
    setLoading(true)
    await approve().finally(() => {
      setLoading(false)
    })
  }

  const handleDepositBtn = async () => {
    setLoading(true)
    await deposit().finally(() => {
      setLoading(false)
    })
    setDepositInput('')
  }

  const handleWithdrawBtn = async () => {
    setLoading(true)
    await withdraw().finally(() => {
      setLoading(false)
    })
    setWithdrawInput('')
  }

  const handleClaimBtn = async () => {
    setLoading(true)
    await claimReward().finally(() => {
      setLoading(false)
    })
  }

  const handleMaxDepositBtn = () => {
    setDepositInput(weiToFixed(props.balance.toString(), 18))
  }

  const handleMaxWithdrawBtn = () => {
    setWithdrawInput(weiToFixed(props.depositBalance.toString(), 18))
  }

  return (
    <>
      <div className={`farming-item ${toggleShow ? 'active' : ''} mb-4`}>
        <Modal
          isOpen={loading}
          onDismiss={() => {
            //
          }}
          maxHeight={90}
        >
          <div className={`modal-loading ${isDark ? 'text-white' : 'text-black'}`}>
            <div className="text-center">
              <div className="spinner-border text-primary mx-auto mb-4" role="status"></div>
              <div className="ml-3">Waiting for Transaction...</div>
            </div>
          </div>
        </Modal>
        <div className="farming-header row" onClick={() => setToggleShow(!toggleShow)}>
          <div className="tokens-group col-6 col-sm-3 d-flex">
            {props.stakingDataItem.token1 === null ? (
              <>
                {/* single token */}
                <div className="pl-sm-0 pl-3 d-flex align-items-center">
                  <div className="img-logo mr-4">
                    <img src={props.stakingDataItem.icon} height="46px" alt="" />
                  </div>
                  <div className={`title-group`}>
                    <div className="text-title">{props.stakingDataItem.token0}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* pair token */}
                <div className="pl-sm-0 pl-3 d-flex align-items-center">
                  <div className="img-logo">
                    <img src={props.stakingDataItem.icon} width="72px" alt="" />
                  </div>
                  <div className={`title-group`}>
                    <div className="text-title">{props.stakingDataItem.token0}</div>
                    <div className="text-title">{props.stakingDataItem.token1}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="tokens-group col-6 col-sm-3 d-flex">
            <div className="d-flex align-items-center">
              <div className="img-logo">
                <img src={props.stakingDataItem.iconReward} width="42px" alt="" />
              </div>
              <div className={`title-group`}>
                <div className="text-title">{props.stakingDataItem.reward}</div>
                <div className="text-sub">
                  {props.data ? numberWithCommasZero(props.data.rewardPerDay.toFixed(2)) : '-'}{' '}
                  {props.stakingDataItem.reward} per day
                </div>
              </div>
            </div>
          </div>

          <div className="deposited-data col-sm-2 col-3 d-sm-flex d-none">
            {props.depositBalance.isZero()
              ? '0'
              : '$' +
                numberWithCommas(
                  (parseFloat(weiToFixed(props.depositBalance.toString(), 2)) * props?.data?.lpPrice).toString()
                )}
            {/* {props.depositBalance.isZero() ? '0' : numberWithCommas(weiToFixed(props.depositBalance.toString(), 2))} */}
          </div>
          <div className="tvl-data col-sm-2 col-3 d-sm-flex d-none">
            ${props.data ? numberWithCommas(props?.data?.tvl?.toFixed(2)) : '...'}
          </div>

          <div className="apr-data col-sm-2 col-6  d-sm-none d-flex">
            <div>
              <div className="">
                <span className="title">TVL</span>{' '}
                <span className="value">${numberWithCommas(props?.data?.tvl?.toFixed(2))}</span>
              </div>
              <span className="title">Deposited</span>{' '}
              <span className="value">
                {props.depositBalance.isZero()
                  ? '0'
                  : '$' +
                    numberWithCommas(
                      (parseFloat(weiToFixed(props.depositBalance.toString(), 2)) * props?.data?.lpPrice).toString()
                    )}
                {/* {props.depositBalance.isZero() ? '0' : numberWithCommas(weiToFixed(props.depositBalance.toString(), 2))} */}
              </span>
            </div>
          </div>

          <div className="apr-data col-sm-2 col-6">
            <div>
              <div className="">
                <span className="title">APR</span>{' '}
                <span className="value">
                  {props.data
                    ? props.data?.apr?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    : '...'}
                  %
                </span>
              </div>
              <span className="title">DAILY</span>{' '}
              <span className="value">{props.data ? (props?.data?.apr / 365).toFixed(2) : '...'}%</span>
              {/* <span className="value">{props?.data?.apr !== Infinity ? aprToApy(props?.data?.apr) : 'Infinity'}%</span> */}
            </div>
          </div>
        </div>
        <hr className="farming-divider" />
        <div className="farming-body d-block">
          <div className="row">
            <div className="col-12 d-block">
              <div className="row">
                <div className="col-12 px-4 px-sm-3 col-sm-6 mt-2 mt-sm-0 ">
                  <div className="w-100">
                    Balance:{' '}
                    <span className="text-balance">
                      {props.balance.isZero() ? '0' : weiToFixed(props.balance.toString(), 2)}
                    </span>
                  </div>
                  <div className="w-100">
                    <input
                      type="number"
                      placeholder="0.00"
                      className="farming-input"
                      value={depositInput}
                      onChange={e => setDepositInput(e.target.value)}
                    />
                    <button onClick={handleMaxDepositBtn} className="btn-max">
                      Max
                    </button>
                  </div>
                  <div className="d-flex gap-1">
                    <button
                      onClick={handleApproveBtn}
                      className={`btn-farming-confirm mb-sm-0 mb-4 ${!props.allowance.isZero() ? 'd-none' : ''}`}
                    >
                      Approve
                    </button>
                    <button
                      onClick={handleDepositBtn}
                      className={`btn-farming-confirm mb-sm-0 mb-4 ${disableDeposit ? 'disabled' : ''}`}
                      disabled={disableDeposit}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
                <div className="col-12 px-4 px-sm-3 col-sm-6">
                  <div className="w-100">
                    Deposited:{' '}
                    <span className="text-balance">
                      {props.depositBalance.isZero() ? '0' : weiToFixed(props.depositBalance.toString(), 2)}
                    </span>
                  </div>
                  <div className="w-100">
                    <input
                      type="number"
                      placeholder="0.00"
                      className="farming-input"
                      value={withdrawInput}
                      onChange={e => setWithdrawInput(e.target.value)}
                    />
                    <button onClick={handleMaxWithdrawBtn} className="btn-max ">
                      Max
                    </button>
                  </div>
                  <div className="w-100">
                    <button
                      onClick={handleWithdrawBtn}
                      className={`btn-farming-confirm btn-block ${disableWithdraw ? 'disabled' : ''}`}
                      disabled={disableWithdraw}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="farming-divider" />
        <div className="farming-footer">
          <div className="text-liquidity d-sm-flex d-none">
            {props.stakingDataItem.token1 === null ? (
              <>
                <a href={props.stakingDataItem.addLpUrl}>Buy Never</a>
              </>
            ) : (
              <>
                <a href={props.stakingDataItem.addLpUrl}>Add liquidity</a>
                <a href={props.stakingDataItem.removeLpUrl}>Remove liquidity</a>
              </>
            )}
          </div>
          <div className="reward">
            <div className="title">Reward:</div>
            <div className="value">
              {props.stakingReward.isZero() ? '0.00' : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
            </div>
            <div className="desc">{props.stakingDataItem.reward}</div>
            <button
              onClick={handleClaimBtn}
              disabled={disableClaim}
              className={`d-sm-block d-none btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
            >
              Claim
            </button>
          </div>
          <button
            onClick={handleClaimBtn}
            disabled={disableClaim}
            className={`d-sm-none d-block  btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
          >
            Claim
          </button>
          <div className="text-liquidity d-sm-none d-flex">
            <a href={props.stakingDataItem.addLpUrl}>Add liquidity</a>
            <a href={props.stakingDataItem.removeLpUrl}>Remove liquidity</a>
          </div>
        </div>
      </div>
    </>
  )
}

interface NeverDieStakingItemProps {
  stakingDataItem: TStakingData
  allowance: BigNumber
  balance: BigNumber
  depositBalance: BigNumber
  stakingReward: BigNumber
  updateAllData: () => void
  data: INeverDieDataResult
}

const NeverDieStakingItem = (props: NeverDieStakingItemProps) => {
  const [toggleShow, setToggleShow] = useState(false)
  // const [weight, setWeight] = useState('0')
  const [depositInput, setDepositInput] = useState('')
  const [withdrawInput, setWithdrawInput] = useState('')
  const depositWei = useWeiAmount(depositInput)
  const withdrawWei = useWeiAmount(withdrawInput)
  const [isDark] = useDarkModeManager()

  const [playClaim] = useSound(claimSound)

  const fairlaunchAddress = NEVERDIE_FARM[56]
  const fairlaunchContract = useContract(fairlaunchAddress, FAIRLAUNCH_ABI, true)
  const lpContract = useContract(
    props.stakingDataItem.token1 !== null ? props.stakingDataItem.lpAddress : props.stakingDataItem.address,
    LP_ABI,
    true
  )

  const [loading, setLoading] = useState(false)

  const approve = async () => {
    const transaction = await lpContract?.approve(fairlaunchAddress, MaxUint256)
    await transaction.wait()
    props.updateAllData()
  }
  const deposit = async () => {
    if (props.stakingDataItem.poolId === 0) {
      const transaction = await fairlaunchContract?.enterStaking(depositWei)
      await transaction.wait()
      props.updateAllData()
    } else {
      const transaction = await fairlaunchContract?.deposit(props.stakingDataItem.poolId, depositWei)
      await transaction.wait()
      props.updateAllData()
    }
  }
  const withdraw = async () => {
    if (props.stakingDataItem.poolId === 0) {
      const transaction = await fairlaunchContract?.leaveStaking(withdrawWei)
      await transaction.wait()
      props.updateAllData()
    } else {
      const transaction = await fairlaunchContract?.withdraw(props.stakingDataItem.poolId, withdrawWei)
      await transaction.wait()
      props.updateAllData()
    }
  }
  const claimReward = async () => {
    const transaction = await fairlaunchContract?.deposit(props.stakingDataItem.poolId, BigNumber.from(0))
    await transaction.wait()
    playClaim()
    props.updateAllData()
  }
  const disableDeposit = !(
    depositInput &&
    BigNumber.from(depositWei).gt(0) &&
    props.balance.gte(BigNumber.from(depositWei)) &&
    !props.allowance.isZero()
  )
  const disableWithdraw = !(
    withdrawInput &&
    BigNumber.from(withdrawWei).gt(0) &&
    props.depositBalance.gte(BigNumber.from(withdrawWei))
  )
  const disableClaim = props.stakingReward.isZero()

  //

  const handleApproveBtn = async () => {
    setLoading(true)
    await approve().finally(() => {
      setLoading(false)
    })
  }

  const handleDepositBtn = async () => {
    setLoading(true)
    await deposit().finally(() => {
      setLoading(false)
    })
    setDepositInput('')
  }

  const handleWithdrawBtn = async () => {
    setLoading(true)
    await withdraw().finally(() => {
      setLoading(false)
    })
    setWithdrawInput('')
  }

  const handleClaimBtn = async () => {
    setLoading(true)
    await claimReward().finally(() => {
      setLoading(false)
    })
  }

  const handleMaxDepositBtn = () => {
    setDepositInput(weiToFixed(props.balance.toString(), 18))
  }

  const handleMaxWithdrawBtn = () => {
    setWithdrawInput(weiToFixed(props.depositBalance.toString(), 18))
  }

  return (
    <>
      <div className={`farming-item ${toggleShow ? 'active' : ''} mb-4`}>
        <Modal
          isOpen={loading}
          onDismiss={() => {
            //
          }}
          maxHeight={90}
        >
          <div className={`modal-loading ${isDark ? 'text-white' : 'text-black'}`}>
            <div className="text-center">
              <div className="spinner-border text-primary mx-auto mb-4" role="status"></div>
              <div className="ml-3">Waiting for Transaction...</div>
            </div>
          </div>
        </Modal>
        <div className="farming-header row" onClick={() => setToggleShow(!toggleShow)}>
          <div className="tokens-group col-6 col-sm-3 d-flex">
            {props.stakingDataItem.token1 === null ? (
              <>
                {/* single token */}
                <div className="pl-sm-0 pl-3 d-flex align-items-center">
                  <div className="img-logo mr-4">
                    <img src={props.stakingDataItem.icon} height="46px" alt="" />
                  </div>
                  <div className={`title-group`}>
                    <div className="text-title">{props.stakingDataItem.token0}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* pair token */}
                <div className="pl-sm-0 pl-3 d-flex align-items-center">
                  <div className="img-logo">
                    <img src={props.stakingDataItem.icon} width="72px" alt="" />
                  </div>
                  <div className={`title-group`}>
                    <div className="text-title">{props.stakingDataItem.token0}</div>
                    <div className="text-title">{props.stakingDataItem.token1}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="tokens-group col-6 col-sm-3 d-flex">
            <div className="d-flex align-items-center">
              <div className="img-logo">
                <img src={props.stakingDataItem.iconReward} width="42px" alt="" />
              </div>
              <div className={`title-group`}>
                <div className="text-title">{props.stakingDataItem.reward}</div>
                <div className="text-sub">
                  {props.data ? numberWithCommasZero(props.data.rewardPerDay.toFixed(2)) : '-'}{' '}
                  {props.stakingDataItem.reward} per day
                </div>
              </div>
            </div>
          </div>

          <div className="deposited-data col-sm-2 col-3 d-sm-flex d-none">
            {props.depositBalance.isZero()
              ? '0'
              : '$' +
                numberWithCommas(
                  (parseFloat(weiToFixed(props.depositBalance.toString(), 2)) * props?.data?.lpPrice).toString()
                )}
            {/* {props.depositBalance.isZero() ? '0' : numberWithCommas(weiToFixed(props.depositBalance.toString(), 2))} */}
          </div>
          <div className="tvl-data col-sm-2 col-3 d-sm-flex d-none">
            ${props.data ? numberWithCommas(props?.data?.tvl?.toFixed(2)) : '...'}
          </div>

          <div className="apr-data col-sm-2 col-6  d-sm-none d-flex">
            <div>
              <div className="">
                <span className="title">TVL</span>{' '}
                <span className="value">${numberWithCommas(props?.data?.tvl?.toFixed(2))}</span>
              </div>
              <span className="title">Deposited</span>{' '}
              <span className="value">
                {props.depositBalance.isZero()
                  ? '0'
                  : '$' +
                    numberWithCommas(
                      (parseFloat(weiToFixed(props.depositBalance.toString(), 2)) * props?.data?.lpPrice).toString()
                    )}
                {/* {props.depositBalance.isZero() ? '0' : numberWithCommas(weiToFixed(props.depositBalance.toString(), 2))} */}
              </span>
            </div>
          </div>

          <div className="apr-data col-sm-2 col-6">
            <div>
              <div className="">
                <span className="title"></span> <span className="value"></span>
              </div>
              <span className="title"></span> <span className="value"></span>
              {/* <span className="value">{props?.data?.apr !== Infinity ? aprToApy(props?.data?.apr) : 'Infinity'}%</span> */}
            </div>
          </div>
        </div>
        <hr className="farming-divider" />
        <div className="farming-body d-block">
          <div className="row">
            <div className="col-12 d-block">
              <div className="row">
                <div className="col-12 px-4 px-sm-3 col-sm-6 mt-2 mt-sm-0 ">
                  <div className="w-100">
                    Balance:{' '}
                    <span className="text-balance">
                      {props.balance.isZero() ? '0' : weiToFixed(props.balance.toString(), 2)}
                    </span>
                  </div>
                  <div className="w-100">
                    <input
                      type="number"
                      placeholder="0.00"
                      className="farming-input"
                      value={depositInput}
                      onChange={e => setDepositInput(e.target.value)}
                    />
                    <button onClick={handleMaxDepositBtn} className="btn-max">
                      Max
                    </button>
                  </div>
                  <div className="d-flex gap-1">
                    <button
                      onClick={handleApproveBtn}
                      className={`btn-farming-confirm mb-sm-0 mb-4 ${!props.allowance.isZero() ? 'd-none' : ''}`}
                    >
                      Approve
                    </button>
                    <button
                      onClick={handleDepositBtn}
                      className={`btn-farming-confirm mb-sm-0 mb-4 ${disableDeposit ? 'disabled' : ''}`}
                      disabled={disableDeposit}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
                <div className="col-12 px-4 px-sm-3 col-sm-6">
                  <div className="w-100">
                    Deposited:{' '}
                    <span className="text-balance">
                      {props.depositBalance.isZero() ? '0' : weiToFixed(props.depositBalance.toString(), 2)}
                    </span>
                  </div>
                  <div className="w-100">
                    <input
                      type="number"
                      placeholder="0.00"
                      className="farming-input"
                      value={withdrawInput}
                      onChange={e => setWithdrawInput(e.target.value)}
                    />
                    <button onClick={handleMaxWithdrawBtn} className="btn-max ">
                      Max
                    </button>
                  </div>
                  <div className="w-100">
                    <button
                      onClick={handleWithdrawBtn}
                      className={`btn-farming-confirm btn-block ${disableWithdraw ? 'disabled' : ''}`}
                      disabled={disableWithdraw}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="farming-divider" />
        <div className="farming-footer">
          <div className="text-liquidity d-sm-flex d-none">
            {props.stakingDataItem.token1 === null ? (
              <>
                <a href={props.stakingDataItem.addLpUrl}>Buy Never</a>
              </>
            ) : (
              <>
                <a href={props.stakingDataItem.addLpUrl}>Add liquidity</a>
                <a href={props.stakingDataItem.removeLpUrl}>Remove liquidity</a>
              </>
            )}
          </div>
          <div className="reward">
            <div className="title">Reward:</div>
            <div className="value">
              {props.stakingReward.isZero() ? '0.00' : numberWithCommas(weiToFixed(props.stakingReward.toString(), 2))}
            </div>
            <div className="desc">{props.stakingDataItem.reward}</div>
            <button
              onClick={handleClaimBtn}
              disabled={disableClaim}
              className={`d-sm-block d-none btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
            >
              Claim
            </button>
          </div>
          <button
            onClick={handleClaimBtn}
            disabled={disableClaim}
            className={`d-sm-none d-block  btn-claim mb-4 mb-sm-0 ${disableClaim ? 'disabled' : ''}`}
          >
            Claim
          </button>
          <div className="text-liquidity d-sm-none d-flex">
            <a href={props.stakingDataItem.addLpUrl}>Add liquidity</a>
            <a href={props.stakingDataItem.removeLpUrl}>Remove liquidity</a>
          </div>
        </div>
      </div>
    </>
  )
}
