import neverIcon from '../../assets/images/farm/never.svg'
import eveBUSDIcon from '../../assets/images/farm/eve-busd.svg'
import neverWBNBIcon from '../../assets/images/farm/never-bnb.svg'
import neverEVEIcon from '../../assets/images/farm/never-eve.svg'
import neverBUSDIcon from '../../assets/images/farm/never-busd.svg'

import usdtBNBIcon from '../../assets/images/farm/usdt-bnb.svg'
import USDCBNBIcon from '../../assets/images/farm/usdc-bnb.svg'
import eveUSDTIcon from '../../assets/images/farm/eve-usdt.svg'
import eveUSDCIcon from '../../assets/images/farm/eve-usdc.svg'

import eveBNBIcon from '../../assets/images/farm/eve-bnb.svg'
import eveBTCIcon from '../../assets/images/farm/eve-btc.svg'
import eveBUNNYIcon from '../../assets/images/farm/eve-bunny.svg'
import eveCAKEIcon from '../../assets/images/farm/eve-cake.svg'
import eveDOPIcon from '../../assets/images/farm/eve-dop.svg'
import eveWADIcon from '../../assets/images/farm/eve-wad.svg'
import eveALPACAIcon from '../../assets/images/farm/eve-alpaca.svg'
import eveSCZIcon from '../../assets/images/farm/eve-scz.svg'

import eveDOTIcon from '../../assets/images/farm/eve-dot.svg'
import eveADAIcon from '../../assets/images/farm/eve-ada.svg'
import eveDAIIcon from '../../assets/images/farm/eve-dai.svg'
import eveLINKIcon from '../../assets/images/farm/eve-link.svg'
import eveUNIIcon from '../../assets/images/farm/eve-uni.svg'
import eveUSTIcon from '../../assets/images/farm/eve-ust.svg'
import eveXRPIcon from '../../assets/images/farm/eve-xrp.svg'

import neverDieIcon from '../../assets/images/farm/neverdie.svg'
import eveIcon from '../../assets/images/farm/eve.svg'

import { reserveType, TStakingData, TStakingDividendData } from './types'
import { ChainId } from '@uniswap/sdk'

// group list
// never tokens = 1
// stable tokens = 2
// other tokens = 3

export const STAKING_LIST = [
  {
    token0: 'EVE',
    token1: 'BUSD',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    address: '',
    lpAddress: '0xC2D7cd44Cf6F81940582b76f1992d563417BADe7',
    icon: eveBUSDIcon,
    iconReward: neverIcon,
    poolId: 1,
    reserveType: reserveType.reserve1,
    group: 2
  } as TStakingData,
  {
    token0: 'NEVER',
    token1: 'WBNB',
    reward: 'NEVER',
    addLpUrl: '#/add/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/BNB',
    removeLpUrl: '#/remove/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/BNB',
    address: '',
    lpAddress: '0xC288B5cB0cf5d443141b460FBa797Ed9aF4eEC15',
    icon: neverWBNBIcon,
    iconReward: neverIcon,
    poolId: 2,
    reserveType: reserveType.reserve0,
    group: 1
  } as TStakingData,
  {
    token0: 'NEVER',
    token1: 'EVE',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB',
    removeLpUrl: '/#/remove/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB',
    address: '',
    lpAddress: '0x8D3e6E3980617809D8C4Cf3a8F52250d47C347a4',
    icon: neverEVEIcon,
    iconReward: neverIcon,
    poolId: 3,
    reserveType: reserveType.reserve0,
    group: 1
  } as TStakingData,
  {
    token0: 'NEVER',
    token1: 'BUSD',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    removeLpUrl: '/#/remove/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    address: '',
    lpAddress: '0x5f33cA991dD2362C8187bb71be089b51a7D5414A',
    icon: neverBUSDIcon,
    iconReward: neverIcon,
    poolId: 4,
    reserveType: reserveType.reserve0,
    group: 1
  } as TStakingData,
  {
    token0: 'NEVER',
    token1: null,
    reward: 'NEVER',
    addLpUrl:
      '/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5',
    removeLpUrl: '',
    address: '0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5',
    lpAddress: '0x5f33cA991dD2362C8187bb71be089b51a7D5414A', // Fake LP
    icon: neverIcon,
    iconReward: neverIcon,
    poolId: 0,
    reserveType: reserveType.reserve0,
    group: 1
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'USDT',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x55d398326f99059fF775485246999027B3197955/0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb',
    removeLpUrl: '/#/remove/0x55d398326f99059fF775485246999027B3197955/0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb',
    address: '',
    lpAddress: '0xA1ff14FAd2f7886DEB92Ffe43d8C1317Fa9562D5',
    icon: eveUSDTIcon,
    iconReward: neverIcon,
    poolId: 7,
    reserveType: reserveType.reserve1,
    group: 2
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'USDC',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb',
    removeLpUrl: '/#/remove/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb',
    address: '',
    lpAddress: '0x46bc506c2cE75DEB9f7D4d1E27E9Da63B6de5Bf5',
    icon: eveUSDCIcon,
    iconReward: neverIcon,
    poolId: 8,
    reserveType: reserveType.reserve1,
    group: 2
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'CAKE',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    address: '',
    lpAddress: '0x02a1239729A67173a60d3945ba35b5B8F253cd77',
    icon: eveCAKEIcon,
    iconReward: neverIcon,
    poolId: 9,
    reserveType: reserveType.reserve1,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'BTC',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    address: '',
    lpAddress: '0x9E6Cc16D7691F94ceA0aba6d34567A1FA62C4EF9',
    icon: eveBTCIcon,
    iconReward: neverIcon,
    poolId: 10,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'BUNNY',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    address: '',
    lpAddress: '0xD9F3054c848E2888Dae00D2c54E8f23e8286e2Cc',
    icon: eveBUNNYIcon,
    iconReward: neverIcon,
    poolId: 11,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'WAD',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x0feadcc3824e7f3c12f40e324a60c23ca51627fc',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x0feadcc3824e7f3c12f40e324a60c23ca51627fc',
    address: '',
    lpAddress: '0x77Fad018b861D91D598E56D539Ba4a4AF25d987c',
    icon: eveWADIcon,
    iconReward: neverIcon,
    poolId: 12,
    reserveType: reserveType.reserve1,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'DOP',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x844fa82f1e54824655470970f7004dd90546bb28',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x844fa82f1e54824655470970f7004dd90546bb28',
    address: '',
    lpAddress: '0x95ebc6F9CAF4E949EF2Ad0D12658387A49124217',
    icon: eveDOPIcon,
    iconReward: neverIcon,
    poolId: 13,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'SCZ',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x39f1014a88c8ec087cedf1bfc7064d24f507b894',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x39f1014a88c8ec087cedf1bfc7064d24f507b894',
    address: '',
    lpAddress: '0x91b8be48E6f20226ad4E70d5ec7Db476151707b5',
    icon: eveSCZIcon,
    iconReward: neverIcon,
    poolId: 14,
    reserveType: reserveType.reserve1,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'ALPACA',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    address: '',
    lpAddress: '0xa5B21a4f43F317AA418C1a3BFB1239A23684Ea5a',
    icon: eveALPACAIcon,
    iconReward: neverIcon,
    poolId: 15,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'BNB',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/BNB',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/BNB',
    address: '',
    lpAddress: '0x584070B8A1860b551d867048858749c9d915C491',
    icon: eveBNBIcon,
    iconReward: neverIcon,
    poolId: 16,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'DOT',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    address: '',
    lpAddress: '0x713A10Aa9962829A5c9Ac6EA79D163c0e8715863',
    icon: eveDOTIcon,
    iconReward: neverIcon,
    poolId: 17,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'UST',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    address: '',
    lpAddress: '0x97051C05Af19C833eBaaDf27a7B0a45De5558adB',
    icon: eveUSTIcon,
    iconReward: neverIcon,
    poolId: 18,
    reserveType: reserveType.reserve1,
    group: 2
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'UNI',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    address: '',
    lpAddress: '0xbf7276B5D54e02be234e6fE8d792b65802cdf36e',
    icon: eveUNIIcon,
    iconReward: neverIcon,
    poolId: 19,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'LINK',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    address: '',
    lpAddress: '0x7Efb78eF1507e60cf99D2E1f613f80e34c8E7b05',
    icon: eveLINKIcon,
    iconReward: neverIcon,
    poolId: 20,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'DAI',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    address: '',
    lpAddress: '0xb5FC99E1ce8C7A5b0368a9681d56Bc3B30619CFA',
    icon: eveDAIIcon,
    iconReward: neverIcon,
    poolId: 21,
    reserveType: reserveType.reserve1,
    group: 2
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'ADA',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    address: '',
    lpAddress: '0x1c15b864DAe8B8dDfcB1010E6d962eFD8D2eB854',
    icon: eveADAIcon,
    iconReward: neverIcon,
    poolId: 22,
    reserveType: reserveType.reserve1,
    group: 3
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'XRP',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    removeLpUrl: '/#/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    address: '',
    lpAddress: '0xFa71D2BB5eC958116b75D66acFF9d2A40e087116',
    icon: eveXRPIcon,
    iconReward: neverIcon,
    poolId: 23,
    reserveType: reserveType.reserve1,
    group: 3
  } as TStakingData,
  {
    token0: 'USDT',
    token1: 'BNB',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x55d398326f99059fF775485246999027B3197955/BNB',
    removeLpUrl: '/#/remove/0x55d398326f99059fF775485246999027B3197955/BNB',
    address: '',
    lpAddress: '0xE74De3E1F468617C39Bee3808293E24ae4Ca3d7F',
    icon: usdtBNBIcon,
    iconReward: neverIcon,
    poolId: 5,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData,
  {
    token0: 'USDC',
    token1: 'BNB',
    reward: 'NEVER',
    addLpUrl: '/#/add/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/BNB',
    removeLpUrl: '/#/remove/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/BNB',
    address: '',
    lpAddress: '0xD67040Afe7d834Cb1e81a6EF5F1cd833c66a5DF0',
    icon: USDCBNBIcon,
    iconReward: neverIcon,
    poolId: 6,
    reserveType: reserveType.reserve0,
    group: 3
  } as TStakingData
]

export const DIVIDEND_STAKING_LIST = [
  {
    token0: 'NeverDIE',
    token1: null,
    reward: 'EVE',
    addLpUrl: '/#/add//',
    removeLpUrl: '/#/remove//',
    address: '0x9860884D2EB17961A3d44fE537f16AA7DA207d72', // Deposit token
    dividendAddress: '0x682A757d3198324915C10D762f6C195312065d01', // SmartChef contract
    icon: neverDieIcon,
    iconReward: eveIcon,
    poolId: 1
  } as TStakingDividendData
]

export const NEVERDIE_STAKING_LIST = [
  {
    token0: 'NEVER',
    token1: 'EVE',
    reward: 'NeverDIE',
    addLpUrl:
      'https://pancakeswap.finance/add/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB',
    removeLpUrl:
      'https://pancakeswap.finance/remove/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB',
    address: '',
    lpAddress: '0xcbc5199dbd9128c07d846a636a20602ff117ca0d',
    icon: neverEVEIcon,
    iconReward: neverDieIcon,
    poolId: 2,
    reserveType: reserveType.reserve1,
    group: 1
  } as TStakingData,
  {
    token0: 'NEVER',
    token1: 'BNB',
    reward: 'NeverDIE',
    addLpUrl: 'https://pancakeswap.finance/add/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/BNB',
    removeLpUrl: 'https://pancakeswap.finance/remove/0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5/BNB',
    address: '',
    lpAddress: '0xfadc49d73dfa8aae86f8deb28715d0b23f02a58d',
    icon: neverWBNBIcon,
    iconReward: neverDieIcon,
    poolId: 1,
    reserveType: reserveType.reserve0,
    group: 1
  } as TStakingData,
  {
    token0: 'EVE',
    token1: 'BNB',
    reward: 'NeverDIE',
    addLpUrl: 'https://pancakeswap.finance/add/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/BNB',
    removeLpUrl: 'https://pancakeswap.finance/remove/0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB/BNB',
    address: '',
    lpAddress: '0xc980f66197c2a57bc8c2630be547f86f50699c01',
    icon: eveBNBIcon,
    iconReward: neverDieIcon,
    poolId: 3,
    reserveType: reserveType.reserve0,
    group: 1
  } as TStakingData
]

export const VIBRANIUM_TOKEN_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.ROPSTEN]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.TESTNET]: '',
  [ChainId.BSC]: '0x1137D5836ef0E0ed9aCc74AeF8ffe2eAf81120b5'
}

// NeverEver
export const NEVER_FARM: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.ROPSTEN]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.TESTNET]: '',
  [ChainId.BSC]: '0x8fb60dd3557c491e04d00a06fdc0618423a3c618'
}

// Legendary
export const NEVERDIE_FARM: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.ROPSTEN]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.TESTNET]: '',
  [ChainId.BSC]: '0x1D4004145cCcbD2c51Eb5Cef8F0bb0C90f047242'
}
