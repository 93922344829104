/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
// import { useDarkModeManager } from 'state/user/hooks'
import './style.css'
import logo from 'assets/images/landing/hero-bg.png'
import dappRadarIcon from 'assets/images/landing/dapp-logo.png'
import coinSnipIcon from 'assets/images/landing/coinsniper-logo.png'
import coinMoonIcon from 'assets/images/landing/coinmooner-logo.png'

const text1 = `THE BETTER FRACTIONAL`
const text2 = `ALGORITHMIC STABLECOIN`
const text3 = `A cryptocurrency being partially backed by collateral and partially stabilized algorithmically.`

function redirectTo(path: string) {
  return (window.location.href = `/#/${path}`)
}

export default function Landing() {
  // const [isDark] = useDarkModeManager()

  return (
    <>
      <div className={`container w-100 mb-4 text-center`}>
        <div className="row" style={{ marginTop: `2%` }}>
          <div
            className="col-md-12  text-white"
            style={{
              background: `url(${logo}) center center no-repeat`,
              paddingBottom: '6.8rem',
              paddingTop: '6.8rem'
            }}
          >
            <h2 className="" style={{ lineHeight: `3.5rem`, fontSize: `3.2rem` }}>
              {text1}
            </h2>
            <h2 className="text-red py-3" style={{ lineHeight: `3.5rem`, fontSize: `3.2rem` }}>
              {text2}
            </h2>
            <h6 className="mb-5 mt-3">{text3}</h6>
            <div className="text-center text-center pt-1">
              <button
                type="button"
                className="btn-white text-truncate mr-sm-2  mb-4 mb-sm-0"
                onClick={() => redirectTo('mint')}
              >
                Mint Ever
              </button>
              <button type="button" className="btn-red text-truncate ml-sm-2" onClick={() => redirectTo('redirect')}>
                Swap
              </button>
            </div>
          </div>
          <div className="col-12 text-center text-gray text-truncate font-weight-bold my-4 mt-5">
            <h5>AS SEEN ON MEDIAS</h5>
          </div>
          <div className="col-12 col-sm-4 pt-2 mb-4 mb-sm-0">
            <a href="https://dappradar.com/binance-smart-chain/exchanges/neverswap" target="_blank">
              <img src={dappRadarIcon} alt="" style={{ height: `2.4rem` }} />
            </a>
          </div>
          <div className="col-12 col-sm-4 mb-4 mb-sm-0">
            <a href="https://coinsniper.net/coin/6724" target="_blank">
              <img src={coinSnipIcon} alt="" style={{ height: `3.2rem` }} />
            </a>
          </div>
          <div className="col-12 col-sm-4 mb-4 mb-sm-0">
            <a href="https://coinmooner.com/coin/1064" target="_blank">
              <img src={coinMoonIcon} alt="" style={{ height: `3.4rem` }} />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
